<div class="product-dialog-header">
  <mat-toolbar class="product-dialog-toolbar">
    <div>
      <button mat-icon-button [matTooltip]="isSaved ? 'Entry already saved' : 'Save for later'"
        [matTooltipShowDelay]="tooltipShowDelay" [disabled]="isSaved" (click)="addToSavedList()"
        class="toolbar-item add-icon">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Request more information" [matTooltipShowDelay]="tooltipShowDelay"
        class="toolbar-item help-icon" (click)="onPartRequestClick($event)">
        <mat-icon>help</mat-icon>
      </button>
      <!-- hide this for now
      <button mat-icon-button
              matTooltip="Queue"
              [matTooltipShowDelay]="tooltipShowDelay"
              class="toolbar-item queue-icon"
              >
        <mat-icon>queue</mat-icon>
      </button>
      -->
    </div>
    <div>
      <button mat-icon-button class="toolbar-item">
        <mat-icon [mat-dialog-close]="getResult()" class="colse-icon">close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>

<!-- <mat-chip-list *ngIf="isFirstFreeDialog && !isInSampleSet">
  <mat-chip color="warn" selected>You have {{ remainingDialogs }} remaining accesses to part’s detailed summary
    page.</mat-chip>
</mat-chip-list> -->
<div mat-dialog-content id="main-content">
  <div class="product-details-grid-wrapper">
    <div class="product-details-grid-row">
      <div class="product-details-col-one">
        <div><strong>Part number: </strong> {{ formattedProduct['part_number'].value }}</div>
        <div><strong>Vendor Part Number: </strong> {{ formattedProduct['vendor_part_number'].value }}</div>
        <div><strong>Manufacturer: </strong> {{ formattedProduct['manufacturer'].value }}</div>
        <div><strong>CAGE: </strong> {{ formattedProduct['cage'].value }}</div>
        <div><strong>Description: </strong> {{ formattedProduct['description'].value }}</div>
        <div><strong>Dose Rate Latchup (rad/sec): </strong> {{ formattedProduct['dose_rate_latchup'].value }}</div>
        <div><strong>Dose Rate Upset (rad/sec): </strong> {{ formattedProduct['dose_rate_upset'].value }}</div>
        <div><strong>Dose rate Survivability (rad/sec): </strong> {{ formattedProduct['dose_rate_survivability'].value
          }}</div>
      </div>
      <div class="product-details-col-two">
        <div><strong>TID HDR (Krad): </strong> {{ formattedProduct['tid_hdr'].value }}</div>
        <div><strong>TID LDR (Krad): </strong> {{ formattedProduct['tid_ldr'].value }}</div>
        <div><strong>DSEE (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['dsee'].value }}</div>
        <div><strong>SEL (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['sel'].value }}</div>
        <div><strong>SEU (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['seu'].value }}</div>
        <div><strong>Neutron (n/cm<sup>2</sup>): </strong> {{ formattedProduct['neutron'].value }}</div>
      </div>
    </div>
    <div class="product-details-grid-row product-details-field-reference">
      <div><strong>Drawing PDF Link: </strong>
        <a *ngIf="formattedProduct['drawing_pdf_link'].value !== 'NA'"
          [attr.href]="formattedProduct['drawing_pdf_link'].value" target="_blank">
          {{ formattedProduct['drawing_pdf_link'].value }}
        </a>
        <div *ngIf="formattedProduct['drawing_pdf_link'].value == 'NA'">{{ formattedProduct['drawing_pdf_link'].value }}
        </div>
        <div *ngIf="(formattedProduct['other_info'] && formattedProduct['other_info'].value !== 'NA')">
          <div><strong>Other Info: </strong> </div>
          <div>{{ formattedProduct['other_info'].value }}</div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <hr />
    <div class="smd-product-details-note">
      <div><strong>Note: </strong> The radiation hardness specifications are shown for the generic part number. The vendor
        may offer different qualification levels depending on the device variant (device types). For example, low dose
        rate qualified devices may be available under device type 02, while device type 01 has been qualified only at high
        dose rate. Please verify the different qualification levels by reviewing the SMD (link above) or from the vendor.
      </div>
    </div>
  </div>
  <div cdkFocusInitial></div>
</div>
<footer>
  &copy; Zero-G Radiation Assurance
</footer>