<mat-dailog-header><h3>Data import instructions</h3></mat-dailog-header>
<mat-dialog-content>
    <div>
        <p>
            Your data should contain a header row followed by
            data. Specify how many rows to skip before the
            header row. <br> Type 0 if the header is located on the
            first row.
        </p>
    </div>
    <div>
        <mat-form-field floatLabel="auto" appearance="standard">
            <mat-label>Number</mat-label>
            <input [(ngModel)]="num" matInput type="number"/>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-action>
    <button mat-button mat-dialog-close="{{num}}">Confirm</button>
    <button mat-button mat-dialog-close="">Cancel</button>
</mat-dialog-action>
