<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="password-reset-request-page-wrapper">
  <mat-card class="password-reset-request-card" [class.mat-elevation-z8]="true">
    <mat-card-content>
      <h3>Password Reset Request</h3>
      <div [formGroup]="passwordResetRequestForm" cdkFocusInitial *ngIf="!isProcessed">
        <div *ngIf="errors" class="error-message">
          <ng-container *ngFor="let error of errors">
            {{ error }}<br />
          </ng-container>
        </div>

        <p>
          <mat-form-field floatLabel="auto" appearance="standard">
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="username" required tabindex="0" cdkFocusInitial (keyup.enter)="onSubmitResetPasswordRequest($event)" />
            <mat-error *ngIf="passwordResetRequestForm.get('username').invalid">{{ getUsernameError() }}</mat-error>
          </mat-form-field>
        </p>

        <mat-card-actions align="end">
          <button mat-raised-button color="primary" [disabled]="passwordResetRequestForm.invalid"
                  (click)="onSubmitResetPasswordRequest($event)"
                  >
            Submit
          </button>
        </mat-card-actions>
      </div>

      <div *ngIf="isProcessed">
        We sent you an email with instructions on how to reset your password.
      </div>
    </mat-card-content>
  </mat-card>
</div>
