<mat-dialog-header>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</mat-dialog-header>
<mat-dialog-content>
    <h3>How it works</h3>

    <p>
        The auto BOM scrub engine searches for the most 
        similar part number using a custom ranking algorithm. 
        The procedure resembles the engineering practice of 
        analysis by part similarity. Similar part numbers can 
        often (but not always) indicate similarity in process 
        and/or design, which can have strong correlation to 
        the radiation susceptibility. However, judgment is 
        advised in how you use the results of the analysis.
        <br>
        <br>
        How do you like this feature? Please share your <a mat-dialog-close class="text-link" routerLink="/inquiry">feedback</a>.
    </p>
</mat-dialog-content>