import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PartRequestsFormDialogComponent } from 'src/app/pages/products/part-requests/part-requests-form-dialog/part-requests-form-dialog.component';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-smdproduct-dialog',
  templateUrl: './smdproduct-dialog.component.html',
  styleUrls: ['./smdproduct-dialog.component.scss']
})
export class SMDProductDialogComponent implements OnInit {
  tooltipShowDelay = 400;

  formattedProduct = {};
  results = [];
  _isSaved: boolean = false;
  get isSaved(): boolean { return this._isSaved; }
  private _addToSavedList = null;

  private _isFirstFreeDialog: boolean = false;
  get isFirstFreeDialog(): boolean { return this._isFirstFreeDialog; }

  private _remainingDialogs: number = 0;
  get remainingDialogs(): number { return this._remainingDialogs; }

  private _isInSampleSet: boolean = false;
  get isInSampleSet(): boolean { return this._isInSampleSet; }


  constructor(
    private _matDialog: MatDialog,
    private _securityRepo:SecurityRepoService,
    @Inject(MAT_DIALOG_DATA) private data) { 
    if(data && typeof data === 'object') {
      if(typeof data.formattedProduct === 'object') {
        this.formattedProduct = data.formattedProduct;
      }

      if(data.results && typeof data.results === 'object') {
        this.results = data.results;
      }

      if(typeof data.isSaved === 'boolean') {
        this._isSaved = data.isSaved;
      }

      if(typeof data.addToSavedList === 'function') {
        this._addToSavedList = data.addToSavedList;
      }

      if(data.isFirstFreeDialog === true) {
        this._isFirstFreeDialog = true;
      }

      if(typeof data.remainingDialogs === 'number') {
        this._remainingDialogs = data.remainingDialogs;
      }

      if(data.isInSampleSet === true) {
        this._isInSampleSet = true;
      }
    }
  }

  ngOnInit(): void {
  }

  addToSavedList() {
    if(this._addToSavedList) {
      this._isSaved = this._addToSavedList();
    }
  }

  getResult() {
    return {
      isSaved: this._isSaved
    };
  }

  onPartRequestClick(event) {
    let config = {
      data: {
        defaultValues: {
          partNumber: '',
          manufacturer: '',
          function: ''
        }
      }
    };

    config.data.defaultValues.partNumber = this.formattedProduct['part_number'] ? this.formattedProduct['part_number'].value : '';
    config.data.defaultValues.manufacturer = this.formattedProduct['manufacturer'] ? this.formattedProduct['manufacturer'].value : '';
    config.data.defaultValues.function = this.formattedProduct['function'] ? this.formattedProduct['function'].value : '';

    const dialogRef = this._matDialog.open(PartRequestsFormDialogComponent, config);
  }

  @HostListener('document:copy',['$event'])
  onCopy() {
    let SelectedText = document.getSelection().toString();
    if (SelectedText.length > 0) {
      this._securityRepo.logCopy(SelectedText);
    }
  }

  @HostListener('document:keydown.control.a',['$event'])
  onSelectEverything(event:Event) {
    event.preventDefault();
    let range = document.createRange();
    range.selectNode(document.getElementById('main-content'));
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(range);
  }
}
