import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss']
})
export class PasswordResetRequestComponent implements OnInit {
  isLoading: boolean = false;

  private _isProcessed: boolean = false;
  get isProcessed() { return this._isProcessed };

  private _errors = [];
  get errors() { return this._errors };

  passwordResetRequestForm = this._formBuilder.group({
    username: ['', [Validators.required]]
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _securityRepo: SecurityRepoService
  ) { }

  ngOnInit(): void {
  }

  getUsernameError() {
    let fieldControl = this.passwordResetRequestForm.get('username');
    if(fieldControl.hasError('required')) {
      return 'Username is required';
    }

    return '';
  }

  onSubmitResetPasswordRequest(event) {
    if(this.passwordResetRequestForm.invalid) {
      return;
    }

    this._errors = [];
    this.isLoading = true;
    this._securityRepo.requestPasswordReset(this.passwordResetRequestForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let errMsg = 'Password reset request failed!';
      let result = {
        errors: [errMsg]
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        this._isProcessed = true;

        return;
      }

      let hasErrors = hasResult && result['errors'] && Array.isArray(result['errors']);
      if(hasErrors) {
        this._errors = result['errors'];
      }
    });
  }
}
