<div class="about-wrapper">
  <span class="mat-display-2">About</span>
  <hr />
  <div class="mat-body">
    <p>
      The Radiation Database is a resource center for engineers looking for radiation test data on microelectronics. It was developed to make the process of searching for radiation data more efficient.
    </p>
    <p>
      <span class="mat-subheading-2">PROBLEM</span>
      Currently, finding radiation data on commercial parts is a tedious and time-consuming process. There lacks a centralized publicly accessible database that is actively maintained.
      The existing databases are mostly out-of-date, difficult to navigate, and not centralized (only contains data from one organization). Also, it can be difficult for a designer or
      someone without radiation effects expertise to extract useful info from papers, reports, etc. We want to create something that's easy to use for anyone.
    </p>
    <p>
      <span class="mat-subheading-2">SOLUTION</span>
      So, we've done the arduous backend work to build an EEE parts database containing radiation test data, and developed an intuitive and functional user interface.
      The database is easily searchable by parameters such as the part number, manufacturer, device function, device technology/process, test facility, LET threshold for SEL, SEGR, SEB,
      TID hardness level, displacement damage fluence level, and more.
      For example, a designer can easily search for an analog-to-digital converter (ADC), that has a minimum SEL LET threshold of 37 MeV-cm<sup>2</sup>/mg
      or any other mission radiation requirement. We've made it easy to find not only the performance specs (e.g. LET threshold, TID level, etc.), but also included pertinent information such as
      the device technology and process (e.g. CMOS, bipolar, etc.), and a description of the results.
      In cases where a more thorough evaluation is needed, we've also provided the source of the data, so one can access the paper or
      test report for a more comprehensive review.
    </p>
    <p>
      We actively update the database from whatever source available, including papers, conference proceedings, presentations, etc. Our database is constantly growing.
      This centralized database and user-friendly graphical interface can significantly reduce the amount of time that the radiation effects engineer
      spends aimlessly searching on Google or scanning endless papers on IEEE.
      The engineer's time should be spent creating solutions!
    </p>
    <p>
      We've also created a space where the user can post queries for part data, in case it's not in our database. So be sure to check out that feature after you've signed up.
    </p>
    <p>
      <span class="mat-subheading-2">HOW TO SIGN UP</span>
      Our radiation database is free to use. Just <a routerLink="/signup">sign up</a> for a free user account and start accessing the database now. Subscribed users can enjoy unlimited usage.
      Request a <a routerLink="/inquiry">quote</a> right now for subscription.
    </p>
    <p>
      <span class="mat-subheading-2">CONTRIBUTE</span> 
      Your data. We offer discounted products and services, including radiation testing and analysis, and/or access to our database our contributors. <a routerLink="/inquiry">Contact us</a> to learn more.
    </p>
    <p>Return <a routerLink="/">home</a> or go to the <a routerLink="/products">database</a>.</p>
  </div>
</div>
