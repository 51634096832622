import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewChild, ElementRef, Renderer2 } from '@angular/core';
import Tabulator from 'tabulator-tables';

@Component({
  selector: 'app-dip-tabulator',
  templateUrl: './dip-tabulator.component.html',
  styleUrls: ['./dip-tabulator.component.scss']
})
export class DipTabulatorComponent implements OnInit, AfterViewInit {
  @Input() tableConfig = null;
  @Output('showmore') showmore = new EventEmitter();

  @ViewChild('dipTabulatorElement', { read: ElementRef }) dipTabulatorElement: ElementRef;

  private _table: Tabulator = null;
  get table() { return this._table };
  if1st:boolean=true;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    if (this.tableConfig) {
      this._table = new Tabulator(this.dipTabulatorElement.nativeElement, this.tableConfig);
      this._table.redraw(true);
    }

  }
  createShowMore() {
    let dvShowMore = document.getElementsByClassName("dvShowMore")[0] as HTMLDivElement;
    if (!!dvShowMore) {dvShowMore.remove();}
    let parentDiv: HTMLDivElement = document.getElementsByClassName("tabulator-tableHolder")[0] as HTMLDivElement;
    if (!parentDiv) return;
    let node = document.createElement("div");
    node.setAttribute("id", "dvShowMore");
    node.setAttribute("class", "dvShowMore");

    node.setAttribute("style", "padding: 8px 0;background:none");
    node.setAttribute("onMouseOver", "this.style.background='none'");
    node.setAttribute("onMouseOut", "this.style.background='none'");

    node.setAttribute("role", "row");
    node.setAttribute("align", "center");
    node.setAttribute("text-align", "center");
    let button = document.createElement("button");
    button.setAttribute("type", "button");
    button.setAttribute("id", "btnShowMore");
    button.setAttribute("style", "border: 1px solid #bbbbbb;  background: #fff;  cursor: pointer;  border-radius: 8px !important; padding: 8px 23px; color: #333333; min-width: 220px; text-align: center; vertical-align: middle; -webkit-border-radius: 8px !important; -moz-webkit-border-radius: 8px !important;");
    button.setAttribute("onMouseOver", "this.style.background='#f5f5f5'");
    button.setAttribute("onMouseOut", "this.style.background='#fff'");
    button.textContent = "Show Relevant Results";
    button.addEventListener("focus", function () {
      this.style.outline = "none";
    });
    button.addEventListener('click', (e) => {
      this.onClickShowMore(e);
    });
    node.append(button);
    parentDiv.append(node);
    if(this.if1st)
    {
      let dvShowMore = document.getElementsByClassName("dvShowMore")[0] as HTMLDivElement;
      if (!!dvShowMore) {dvShowMore.hidden=true;}
    }
    this.if1st=false;
  }

  createTable(config) {
    if (config && typeof config === 'object') {
      this._table = new Tabulator(this.dipTabulatorElement.nativeElement, config);
      this._table.redraw(true);
      this.createShowMore();
    }
  }


  setFilter(filters) {
    if (this._table) {
        this._table.setFilter(filters);
    }
  }

  setData(data) {
    if (this._table) {
      this._table.setData(data);
    }
  }
  onClickShowMore(event: MouseEvent) {
    this.showmore.emit(event);
  }

  getData() {
    return (this._table)? this._table.getData() : [];
  }

  clearSorters() {
    if (this._table) {
      this._table.clearSort();
    }
  }

  updateColumnDefs(array) {
    if (this._table) {
      this._table.setColumns(array);
    }
  }

  updatePlaceholder(text: string) {
    if (this._table && text.length > 0) {
      let placeholderElement = document.getElementById('tabulatorPlaceholder');
      if (placeholderElement) {
        placeholderElement.innerHTML = text;
      }
    }
  }
}
