import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DipCookieService {

  constructor() { }

  setCookie(name: string, value = null) {
    if(typeof name !== 'string' || name.length < 1) {
      return false;
    }

    if(value === null) {
      this.deleteCookie(name);

      return true;
    }

    let cVal = '';
    if(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      cVal = encodeURIComponent(value);
    }
    else if(typeof value === 'object') {
      if(value) {
        cVal = encodeURIComponent(JSON.stringify(value));
      }
      else {
        cVal = '';
      }
    }
    else {
      return false;
    }

    document.cookie = name + '=' + cVal + '; path="/"';
    return true;
  }

  getCookie(name: string) {
    if(typeof name !== 'string' || name.length < 1) {
      return undefined;
    }

    if(typeof document.cookie !== 'string' || document.cookie.length < 1) {
      return undefined;
    }

    const re = /;\s*/;
    let cookieArr = document.cookie.split(re);

    let found = undefined;
    for(const item of cookieArr) {
      let itemArr = item.split('=');
      if(itemArr.length == 2 && itemArr[0] === name) {
        found = decodeURIComponent(itemArr[1]);
        break;
      }
    }

    return found;
  }

  deleteCookie(name: string) {
    if(typeof name !== 'string' || name.length < 1) {
      return false;
    }

    document.cookie = name + '=; path="/"; max-age=0; expires=0';
    return true;
  }
}
