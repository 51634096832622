import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UsersRepoService } from 'src/app/repositories/users-repo/users-repo.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  token: string = '';
  
  error: string = '';
  isTokenExpired: boolean = false;
  isTokenRetrieved: boolean = false;

  signupForm = this.formBuilder.group({
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-Z0-9!@#$%^&]*')
      ]
    ]
  });
  
  isCreated: boolean = false;
  isLoading: boolean = false;

  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private usersRepo: UsersRepoService,
              private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (queryParam: any) => {
        if (queryParam['token']!== undefined && queryParam['token'].length > 0) {
          this.token = queryParam['token'];
          this.isTokenRetrieved = true;
        }
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._changeDetectorRef.detectChanges();
    }, 300);
  }

  public getPasswordError() {
    let fieldControl = this.signupForm.get('password');
    if(fieldControl.hasError('required')) {
      return 'Password is required';
    }

    if(fieldControl.hasError('minlength')) {
      return 'Password must be at least ' + fieldControl.errors.minlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('maxlength')) {
      return 'Password cannot be longer than ' + fieldControl.errors.maxlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('pattern')) {
      return 'Password contains invalid charcters. Allowed characters are a-z, A-Z, 0-9, and the following special characters: !@#$%^&';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  public onCreate() {
    let pass = this.signupForm.get('password').value;
    this.error = '';
    let params = {
      token: this.token,
      password: pass
    }
    this.isLoading = true;
    this.usersRepo.createAccountPassword(params).subscribe (result => {
      this.isLoading = false;
      if (!result['success']) {
        if (result['message'] === 'Token has expired!') {
          this.isTokenExpired = true;
        } else {
          this.error = result['message'];
        }
      } else {
        this.isCreated = true;
      }
    });
  }
}
