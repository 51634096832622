import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";

import Tabulator from 'tabulator-tables';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import { SpecifyDialogComponent } from './specify-dialog/specify-dialog.component';

import {ProductsRepoService} from "../../../repositories/products-repo/products-repo.service";
import {SecurityRepoService} from "../../../repositories/security-repo/security-repo.service";
import {SearchDialogComponent} from "./search-dialog/search-dialog.component";
import {RowPopupComponent} from "./row-popup/row-popup.component";
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { UsersRepoService } from 'src/app/repositories/users-repo/users-repo.service';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';



@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    private file: NgxFileDropEntry;
    private _fileReady: boolean = false;
    private _acceptedFormats: String = '.xls, .xlsx, .csv';
    private _acceptedFileExtensions: String[] = ['xls', 'xlsx', 'csv'];
    private _rawTableData: object[] = [];
    private _tableConfig: object[] = [];
    private _tableDataReady: boolean = false;
    private _tableReady: boolean = false;
    private _table: Tabulator;
    private _displayTable: boolean = true;
    private _columnNames: String[] = [];
    private _isTableAutopop: boolean = false;
    private _isLoading: boolean = false;
    rowsToSkip: number = 0;
    private _isStep: number = 0;
    private choosenColumn: string;
    private userMembershipType: number = 0;
    private isUserTrialExpired: boolean = false;
    private limit: number = 0;
    private positions = [];
    @Output() refEmitter = new EventEmitter<string>();


    constructor(private http: HttpClient,
                private _productsRepo: ProductsRepoService,
                private snackBar: MatSnackBar,
                private dialog: MatDialog,
                private _securityRepo: SecurityRepoService,
                private _usersRepo: UsersRepoService
    ) {}

    ngOnInit(): void {}

    public dropped(files: NgxFileDropEntry[]) {
        this.onFileLeave();
        if (this._securityRepo.isAuthenticated) {
            let specifyDialog = this.dialog.open(SpecifyDialogComponent,{
                width:"500px"
            });
            specifyDialog.afterClosed().subscribe(number => {
                let num = parseInt(number);
                if (num >= 0) {
                    this.rowsToSkip = num;
                    const droppedFile = files[0];
                    // Is it a file?
                    if (droppedFile.fileEntry.isFile && files.length == 1) {
                        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                        let fileExtension = droppedFile.relativePath.split('.').pop();
                        if (this._acceptedFileExtensions.indexOf(fileExtension) !== -1) {
                            fileEntry.file((file: File) => {
                                this.file = files[0];
                                this._fileReady = true;
                            });
                        } else {
                            this.displayErrorSnackBar('Incorrect file extension. Only .xls, .xlsx and .csv allowed');
                        }
                    } else {
                        this.displayErrorSnackBar('Directories are not allowed');
                    }
                }
            });    
        } else {
            this.displayErrorSnackBar('Sign up for an account to use this feature');
        }
    }

    public onUploadFile() {
        if (this.fileReady) {
            this._isLoading = true;
            const fileEntry = this.file.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this._productsRepo.uploadFile(file, this.file.relativePath, this.rowsToSkip).subscribe(
                    data => {
                        this._rawTableData = data;
                        this._tableDataReady = true;

                        this._displayTable = false;
                        this.createTable();
                    }
                );
            });
        } else {
            this.displayErrorSnackBar('File not found. Please select one using Browse or Drag and drop');
        }
    }

    public getFileName(): String {
        if (this.fileReady) {
            return this.file.relativePath;
        }
        return '';
    }

    public createTable() {
        if (this._tableDataReady) {
            this._isStep = 1;
            this._columnNames = Object.keys(this._rawTableData[0]);
            this._columnNames.forEach(value => {
                let elem = {
                    title: value,
                    field: value
                }
                this._tableConfig.push(elem);
            });
            this._table = new Tabulator('#userTable', {
                columns: this._tableConfig,
                height: '100%',
                layout: 'fitColumns',
                responsiveLayout: true,
                resizableRows: true,
                placeholder: 'No data to display!',
                pagination: 'local',
                paginationSize: 20,
                paginationSizeSelector: true
            });
            this._isLoading = false;
            this._table.setData(this._rawTableData);
            this._tableReady = true;
        }
    }

    public restart() {
        this.file = null;
        this._fileReady = false;
        this._rawTableData = [];
        this._tableConfig = [];
        this._tableDataReady = false;
        this._tableReady = false;
        if (this._table) {
            this._displayTable = true;
            this._table.clearData();
        }
        this._columnNames = [];
        this._isTableAutopop = false;
        this._isLoading = false;
        this._isStep = 0;
        this.choosenColumn = "";
    }

    public clearFilters() {
        if (this._table) {
            this._table.setFilter([]);
        }
    }

    public onSelectColumn() {
        if (this._columnNames && !this._isTableAutopop) {
            let searchDialog = this.dialog.open(SearchDialogComponent, {data: this._columnNames});
            searchDialog.afterClosed().subscribe(column => {
                if (column) {
                    this._isStep = 2;
                    this.choosenColumn = column;
                    this._tableConfig.forEach(col => {
                        if(col["title"] === column) {
                            col["titleFormatter"] = function(cell, formatterParams, onRendered){
                                setTimeout(function(){                   
                                    cell.getElement().parentNode.parentNode.style.backgroundColor = "#F3F3F3";
                                 },100)
                                return cell.getValue();
                            }
                            col["formatter"] = function(cell, formatterParams, onRendered){
                                cell.getElement().style.backgroundColor = "#F3F3F3";
                                return cell.getValue();
                            }
                        }
                    });
                    this._table.setColumns(this._tableConfig);
                }
            });
        }
    }

    public onSearch() {
        this._isLoading = true;
        this._rawTableData.forEach((element, index) => {
            this.positions.push([element, index]);
        });
        if (this._securityRepo.user !== null) {
            let username = this._securityRepo.user.username;
            let params = {
                username: username
            };
            this._usersRepo.getMembership(params).subscribe(result => {
                if (result['isSuccess']) {
                    if (!this._securityRepo.isAdmin) {
                        this.userMembershipType = result['membership'];
                        this.isUserTrialExpired = result['isExpired'];
                        let organizationSearches = result['organisationSearches'];
                        let organizationLimit = result['organizationLimit'];
    
                        
                        let orgSearchesLeft = organizationLimit - organizationSearches;
                        this.limit = (orgSearchesLeft > 0)? orgSearchesLeft : 0;

                        if (this.limit == 0) {
                            this._isLoading = false;
                            this.displayLimitSnackBar()
                            return;
                        }

                        if (this.userMembershipType == 1) {
                            if (this.isUserTrialExpired) {
                                this.limit = (result['autoBOMSearches'] >= 10)? 0 : 10 - result['autoBOMSearches'];
                            } else {
                                this.limit = (result['autoBOMSearches'] >= 50)? 0 : 50 - result['autoBOMSearches'];
                            }
                        }
                    }

                    this._rawTableData.forEach((elem,i) => {
                        if(elem[this.choosenColumn] == null || elem[this.choosenColumn].length == 0) {
                            this._rawTableData.splice(i,1);
                        }
                    });
                    
                    let maxQueries = this._rawTableData.length;
                    if (this.userMembershipType == 1 && !this._securityRepo.isAdmin) {
                        maxQueries = (maxQueries > this.limit)? this.limit : maxQueries;
                        if (maxQueries == 0) {
                            this._isLoading = false;
                            this.displayErrorSnackBar('You have used the free search allotment. Become a subscriber to continue enjoying this feature.');
                            return;
                        }
                    }
                    let count = 0;
                    let resultArray = [];
                    let exactMatchesCount = 0;
                    this._rawTableData.forEach((value,i) => {
                        if (i < maxQueries) {
                            let href = environment.apiUrl + 'products';
                            let requestUrl = `${href}?params=${encodeURIComponent(JSON.stringify({
                                sorters: [],
                                filters: [{
                                    field: 'part_number',
                                    type: 'LIKE',
                                    value: value[this.choosenColumn]
                                }],
                                partNumber: value[this.choosenColumn],
                                useSmartSearch: true,
                                useAutoBOM: true
                            }))}`;
                            this.http.get<any>(requestUrl).subscribe( result => {
                                if (result.exactMatch) {
                                    exactMatchesCount++;
                                }
                                let resultItems = result.items;
                                let originalPartNumber = result.partNumber
                                if (resultItems.length > 0) {
                                    let array = [];
                                    resultItems.forEach(item => {
                                        array.push({
                                            let_min: item.let_min,
                                            let_max: item.let_max,
                                            tid_min: item.tid_min,
                                            tid_max: item.tid_max,
                                            dd_fluence: item.dd_fluence,
                                            survival_voltage: item.survival_voltage,
                                            partNumber: item.part_number,
                                            reference: item.reference,
                                            part_number: originalPartNumber
                                        });
                                    })
                                    resultArray.push(this.getData(array));
                                } else {
                                    resultArray.push({
                                        let_min: ['NA', 'NA', 'NA'],
                                        let_min_ref: ['NA', 'NA', 'NA'],
                                        let_min_ref_text: ['NA', 'NA', 'NA'],
                                        let_max: ['NA', 'NA', 'NA'],
                                        let_max_ref: ['NA', 'NA', 'NA'],
                                        let_max_ref_text: ['NA', 'NA', 'NA'],
                                        tid_min: ['NA', 'NA', 'NA'],
                                        tid_min_ref: ['NA', 'NA', 'NA'],
                                        tid_min_ref_text: ['NA', 'NA', 'NA'],
                                        tid_max: ['NA', 'NA', 'NA'],
                                        tid_max_ref: ['NA', 'NA', 'NA'],
                                        tid_max_ref_text: ['NA', 'NA', 'NA'],
                                        dd_fluence: [],
                                        dd_fluence_ref: [],
                                        dd_fluence_ref_text: [],
                                        survival_voltage: [],
                                        survival_voltage_ref: [],
                                        survival_voltage_ref_text: [],
                                        part_number: originalPartNumber
                                    });
                                }
                                count++;
                                if (count == maxQueries) {
                                    if (Math.round(maxQueries/2) < exactMatchesCount && !this._securityRepo.isAdmin) {
                                        let params = {
                                            username: this._securityRepo.user.username,
                                            exactMatches: exactMatchesCount,
                                            total: maxQueries
                                        }
                                        this._securityRepo.alertAdmin(params);
                                    }
                                    this._tableConfig = [
                                        {title: "id", field: "id", visible: false},
                                        {title: this.choosenColumn, field: "part_number"},
                                        {title: "LET Min", field: "let_min"},
                                        {title: "let_min_ref", field: "let_min_ref", visible: false},
                                        {title: "let_min_ref_text", field: "let_min_ref_text", visible: false},
                                        {title: "LET Max", field: "let_max"},
                                        {title: "let_max_ref", field: "let_max_ref", visible: false},
                                        {title: "let_max_ref_text", field: "let_max_ref_text", visible: false},
                                        {title: "TID Min", field: "tid_min"},
                                        {title: "tid_min_ref", field: "tid_min_ref", visible: false},
                                        {title: "tid_min_ref_text", field: "tid_min_ref_text", visible: false},
                                        {title: "TID Max", field: "tid_max"},
                                        {title: "tid_max_ref", field: "tid_max_ref", visible: false},
                                        {title: "tid_max_ref_text", field: "tid_max_ref_text", visible: false},
                                        {title: "DD Fluence", field: "dd_fluence"},
                                        {title: "dd_fluence_ref", field: "dd_fluence_ref", visible: false},
                                        {
                                            title: "dd_fluence_ref_text",
                                            field: "dd_fluence_ref_text",
                                            visible: false
                                        },
                                        {title: "Survival Voltage", field: "survival_voltage"},
                                        {
                                            title: "survival_voltage_ref",
                                            field: "survival_voltage_ref",
                                            visible: false
                                        },
                                        {
                                            title: "survival_voltage_ref_text",
                                            field: "survival_voltage_ref_text",
                                            visible: false
                                        }
                                    ];
                                    this._rawTableData = resultArray;
                                    let displayData = [];
                                    resultArray.forEach((elem,index) => {
                                        this.positions.forEach((position) => {
                                            if (elem['part_number'] === position[0][this.choosenColumn]) {
                                                displayData[position[1]] = {
                                                    id: index,
                                                    let_min: elem['let_min'][0],
                                                    let_min_ref: elem['let_min_ref'][0],
                                                    let_min_ref_text: elem['let_min_ref_text'][0],
                                                    let_max: elem['let_max'][0],
                                                    let_max_ref: elem['let_max_ref'][0],
                                                    let_max_ref_text: elem['let_max_ref_text'][0],
                                                    tid_min: elem['tid_min'][0],
                                                    tid_min_ref: elem['tid_min_ref'][0],
                                                    tid_min_ref_text: elem['tid_min_ref_text'][0],
                                                    tid_max: elem['tid_max'][0],
                                                    tid_max_ref: elem['tid_max_ref'][0],
                                                    tid_max_ref_text: elem['tid_max_ref_text'][0],
                                                    dd_fluence: (elem['dd_fluence'].length > 0)? elem['dd_fluence'][0] : 'NA',
                                                    dd_fluence_ref: (elem['dd_fluence_ref'].length > 0)? elem['dd_fluence_ref'][0] : 'NA',
                                                    dd_fluence_ref_text: (elem['dd_fluence_ref_text'].length > 0)? elem['dd_fluence_ref_text'][0] : 'NA',
                                                    survival_voltage: (elem['survival_voltage'].length > 0)? elem['survival_voltage'][0] : 'NA',
                                                    survival_voltage_ref: (elem['survival_voltage_ref'].length > 0)? elem['survival_voltage_ref'][0] : 'NA',
                                                    survival_voltage_ref_text: (elem['survival_voltage_ref_text'].length > 0)? elem['survival_voltage_ref_text'][0] : 'NA',
                                                    part_number: elem['part_number']
                                                }
                                            }
                                        });
                                    });
                                    this._table = new Tabulator("#userTable", {
                                        data: displayData,
                                        columns: this._tableConfig,
                                        height: '90%',
                                        layout: 'fitColumns',
                                        responsiveLayout: true,
                                        resizableRows: true,
                                        placeholder: 'No data to display!',
                                        pagination: 'local',
                                        paginationSize: 20,
                                        paginationSizeSelector: true,
                                        rowClick: (e, row) => {
                                            this.openRowPopup(row);
                                        }
                                    });
                                    this._isStep = 3;
                                    this._isLoading = false;
                                    this._isTableAutopop = true;
                                }
                            });    
                        }
                    });
                } else {
                    this._isLoading = false;
                    this.displayErrorSnackBar(result['errors'][0]);
                }
            });
        } else {
            this._isLoading = false;
            this.displayErrorSnackBar('Sign up for an account to use this feature');
        }
    }

    public displayErrorSnackBar(message: string) {
        this.snackBar.open(message, "", {
            duration: 3000,
            panelClass: ['red-snackbar'],
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }

    public displayLimitSnackBar() {
        this.snackBar.openFromComponent(CustomSnackBarComponent,{   
                panelClass: ['red-snackbar'],
                verticalPosition: 'top',
                horizontalPosition: 'right'
            });
    }

    public getData(array) {
        let result = {
            let_min: ['NA', 'NA', 'NA'],
            let_min_ref: ['NA', 'NA', 'NA'],
            let_min_ref_text: ['NA', 'NA', 'NA'],
            let_max: ['NA', 'NA', 'NA'],
            let_max_ref: ['NA', 'NA', 'NA'],
            let_max_ref_text: ['NA', 'NA', 'NA'],
            tid_min: ['NA', 'NA', 'NA'],
            tid_min_ref: ['NA', 'NA', 'NA'],
            tid_min_ref_text: ['NA', 'NA', 'NA'],
            tid_max: ['NA', 'NA', 'NA'],
            tid_max_ref: ['NA', 'NA', 'NA'],
            tid_max_ref_text: ['NA', 'NA', 'NA'],
            dd_fluence: [],
            dd_fluence_ref: [],
            dd_fluence_ref_text: [],
            survival_voltage: [],
            survival_voltage_ref: [],
            survival_voltage_ref_text: [],
            part_number: ""
        };
        if (array instanceof Array) {
            array.forEach(part => {
                result["part_number"] = (result["part_number"].length == 0) ? part["part_number"] : result["part_number"];
                Object.keys(part).forEach(key => {
                    let str1 = key.substring(0, 3);
                    let str2 = key.substring(4);
                    if (key !== 'partNumber' && key !== 'reference' && key !== 'part_number') {
                            if (str1 === 'let' || str1 === 'tid') {
                                if (str2 === 'min' && part[key] !== 'NA') {
                                    let i = 0;
                                    result[key].forEach(elem => {
                                        if (elem === 'NA' && result[str1 + '_max'][i] == 'NA' && part[key] !== 'NA') {
                                            result[key][i] = part[key];
                                            result[key + '_ref'][i] = part['partNumber'];
                                            result[key + '_ref_text'][i] = part['reference'];
                                            part[key] = 'NA';
                                        }
                                        if (elem === 'NA' && typeof result[str1 + '_max'][i] === 'number' && part[key] !== 'NA') {
                                            if (part[key] < result[str1 + '_max'][i]) {
                                                result[key][i] = part[key];
                                                result[key + '_ref'][i] = part['partNumber'];
                                                result[key + '_ref_text'][i] = part['reference'];
                                                part[key] = 'NA';
                                            }
                                        }
                                        i++;
                                    });
                                }
                                if (str2 === 'max' && part[key] !== 'NA') {
                                    let i = 0;
                                    result[key].forEach(elem => {
                                        if (elem == 'NA' && result[str1 + '_min'][i] == 'NA' && part[key] !== 'NA') {
                                            result[key][i] = part[key];
                                            result[key + '_ref'][i] = part['partNumber'];
                                            result[key + '_ref_text'][i] = part['reference'];
                                            part[key] = 'NA';
                                        }
                                        if (elem == 'NA' && typeof result[str1 + '_min'][i] === 'number' && part[key] !== 'NA') {
                                            if (part[key] > result[str1 + '_min'][i]) {
                                                result[key][i] = part[key];
                                                result[key + '_ref'][i] = part['partNumber'];
                                                result[key + '_ref_text'][i] = part['reference'];
                                                part[key] = 'NA';
                                            }
                                        }
                                        i++;
                                    });
                                }
                            }
                            if (part[key] !== 'NA' && str2 !== 'min' && str2 !== 'max') {
                                if (key == 'dd_fluence') {
                                    result[key].push(parseInt(part[key]).toExponential());
                                } else {
                                    result[key].push(part[key]);
                                }
                                result[key + '_ref'].push(part['partNumber']);
                                result[key + '_ref_text'].push(part['reference']);
                            }    
                        }    
                });
            });
        }
        return result;
    }

    public openRowPopup(row) {
        let i = row.getIndex();
        let copy = JSON.parse(JSON.stringify(this._rawTableData[i]));
        let popup = this.dialog.open(RowPopupComponent, {
            data: this._rawTableData[i], 
            width: '60%', 
            panelClass: ['product-dialog', 'data-popup'],
            autoFocus: false
        });
        const sub = popup.componentInstance.refEmitter.subscribe((value: string) => {
            this.refEmitter.emit(value);
        });
        popup.afterClosed().subscribe(() => {
            Object.keys(copy).forEach(key => {
                if (copy[key].length > 0 && copy[key][0] !== this._rawTableData[i][key][0]) {
                    this._table.updateData([{
                        id: i,
                        [key]: (this._rawTableData[i][key].length >= 1)? this._rawTableData[i][key][0] : 'NA'
                    }]);
                }
            });
            sub.unsubscribe();
        });
    }

    public getNonNAValue(array: Array<any>) {
        let result = 'NA';
        let block = false;
        array.forEach((elem, index) => {
            if (elem !== 'NA' && !block) {
                result = elem;
                block = true;
            }
        });
        return result;
    }

    public onDownloadSpreadsheet() {
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Auto BOM scrub",
            Subject: "UserWorksheet",
            Author: this._securityRepo.user.username,
            CreatedDate: new Date()
        }
        wb.SheetNames.push("Main sheet");
        let ws_data = this.parseData(this._rawTableData);
        wb.Sheets["Main sheet"] = XLSX.utils.aoa_to_sheet(ws_data);
        let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

        function s2ab(s) {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(wbout)], {type: "application/octet-stream"}), 'userSpreadsheet.xlsx');
    }

    public parseData(array: object[]): string[][] {
        let returnArray = [["Part Number", "SEL / DSEE LET", "TID", "Survival Voltage", "DD fluence", "Reference"]];
        array.forEach(part => {
            let refCounter = 0;
            let row = [];
            let letRefStrEnd = '', tidRefStrEnd = '', svRefStrEnd = '', ddRefStrEnd = '', allReferences = '';
            let letRefStrStart = '', tidRefStrStart = '', svRefStrStart = '', ddRefStrStart = '';
            Object.keys(part).forEach(key => {
                let keyFirstPart = key.substring(0, key.indexOf('_'));
                if (keyFirstPart === 'let' && part[key + '_ref'] !== undefined && part[key + '_ref'][0] != 'NA' && part[key + '_ref'].length > 0) {
                    if (letRefStrStart.trim() !== part[key + '_ref'][0]) {
                        let buff = ' [' + ++refCounter + ']';
                        letRefStrStart = letRefStrStart.concat(' ' + (part[key + '_ref'][0]));
                        if (!allReferences.includes(part[key + '_ref_text'][0])) {
                            allReferences = allReferences.concat(buff + ' ' + part[key + '_ref_text'][0] + " \n");
                            letRefStrEnd = letRefStrEnd.concat(buff);
                        } else {
                            let position = allReferences.indexOf(part[key + '_ref_text'][0]) - 3;
                            let char = allReferences.charAt(position);
                            buff = ' [' + char + '] ';
                            letRefStrEnd = letRefStrEnd.concat(buff);
                        }
                    }
                }
                if (keyFirstPart === 'tid' && part[key + '_ref'] !== undefined && part[key + '_ref'][0] != 'NA' && part[key + '_ref'].length > 0) {
                    if (tidRefStrStart.trim() !== part[key + '_ref'][0]) {
                        let buff = ' [' + ++refCounter + ']';
                        tidRefStrStart = tidRefStrStart.concat(' ' + part[key + '_ref'][0]);
                        if (!allReferences.includes(part[key + '_ref_text'][0])) {
                            allReferences = allReferences.concat(buff + ' ' + part[key + '_ref_text'][0] + " \n");
                            tidRefStrEnd = tidRefStrEnd.concat(buff);
                        } else {
                            let position = allReferences.indexOf(part[key + '_ref_text'][0]) - 3;
                            let char = allReferences.charAt(position);
                            buff = ' [' + char + '] ';
                            tidRefStrEnd = tidRefStrEnd.concat(buff);
                        }
                    }
                }
                if (keyFirstPart === 'survival' && part[key + '_ref'] !== undefined && part[key + '_ref'].length > 0) {
                    let buff = ' [' + ++refCounter + ']';
                    svRefStrStart = svRefStrStart.concat(' ' + part[key + '_ref'][0]);
                    if (!allReferences.includes(part[key + '_ref_text'][0])) {
                        allReferences = allReferences.concat(buff + ' ' + part[key + '_ref_text'][0] + " \n");
                        svRefStrEnd = svRefStrEnd.concat(buff);
                    } else {
                        let position = allReferences.indexOf(part[key + '_ref_text'][0]) - 3;
                        let char = allReferences.charAt(position);
                        buff = ' [' + char + '] ';
                        svRefStrEnd = svRefStrEnd.concat(buff);
                    }
                }
                if (keyFirstPart === 'dd' && part[key + '_ref'] !== undefined && part[key + '_ref'].length > 0) {
                    let buff = ' [' + ++refCounter + ']';
                    ddRefStrStart = ddRefStrStart.concat(' ' + part[key + '_ref'][0]);
                    if (!allReferences.includes(part[key + '_ref_text'][0])) {
                        allReferences = allReferences.concat(buff + ' ' + part[key + '_ref_text'][0] + " \n");
                        ddRefStrEnd = ddRefStrEnd.concat(buff);
                    } else {
                        let position = allReferences.indexOf(part[key + '_ref_text'][0]) - 3;
                        let char = allReferences.charAt(position);
                        buff = ' [' + char + '] ';
                        ddRefStrEnd = ddRefStrEnd.concat(buff);
                    }
                }
            });
            letRefStrStart = (letRefStrStart)? letRefStrStart.concat(':') : letRefStrStart;
            tidRefStrStart = (tidRefStrStart)? tidRefStrStart.concat(':') : tidRefStrStart;
            svRefStrStart = (svRefStrStart)? svRefStrStart.concat(':') : svRefStrStart;
            ddRefStrStart = (ddRefStrStart)? ddRefStrStart.concat(':') : ddRefStrStart;
            row.push(part["part_number"]);
            row.push(letRefStrStart + this.parseTidOrLet((part['let_min'][0] !== undefined && part['let_min'].length > 0)? part['let_min'][0] : 'NA', (part['let_max'][0] !== undefined && part['let_max'].length > 0)? part['let_max'][0] : 'NA', 'LETth') + letRefStrEnd);
            row.push(tidRefStrStart + this.parseTidOrLet((part['tid_min'][0] !== undefined && part['tid_min'].length > 0)? part['tid_min'][0] : 'NA', (part['tid_max'][0] !== undefined && part['tid_max'].length > 0)? part['tid_max'][0] : 'NA', 'TID') + tidRefStrEnd);
            row.push(svRefStrStart + ((part["survival_voltage"][0] !== undefined && part['survival_voltage'].length > 0)? part["survival_voltage"][0] : 'NA') + svRefStrEnd);
            row.push(ddRefStrStart + ((part["dd_fluence"][0] !== undefined && part['dd_fluence'].length > 0)? part["dd_fluence"][0] : 'NA') + ddRefStrEnd);

            row.push(allReferences);

            returnArray.push(row);
        });
        return returnArray;
    }

    public parseTidOrLet(val_min, val_max, str): string {
        if (val_min !== 'NA' && val_max !== 'NA') {
            return val_min + ' < ' + str + ' < ' + val_max;
        }
        if (val_min !== 'NA' && val_max === 'NA') {
            return str + ' > ' + val_min;
        }
        return (val_min === 'NA' && val_max !== 'NA') ? str + ' < ' + val_max : 'NA';
    }

    public onFiltersChanged (filters: object[]) {
        if (this._table) {
            this._table.setFilter(filters)
        }
    }

    public onMoreInfo() {
        let btn = document.getElementById("info-icon");
        let dialogTop = btn.offsetTop;
        let dialogleft = btn.offsetLeft;
        this.dialog.open(InfoDialogComponent, 
            {
            height: "375px", 
            width: "500px",
            backdropClass: 'g-transparent-backdrop',
            panelClass: 'info-dialog',
            disableClose: false,
            hasBackdrop: true,
            autoFocus: false,
            position: {
                left: `${dialogleft - 250}px`,
                top: `${dialogTop + 140}px`
            }
        });
    }

    onFileOver() {
        let target = document.getElementById('ngx-drop').firstElementChild.firstElementChild.getElementsByTagName('div')[0];
        if (target) {
            target.classList.add('ngx-drop-background--over');
        }
    }

    onFileLeave() {
        let target = document.getElementById('ngx-drop').firstElementChild.firstElementChild.getElementsByTagName('div')[0];
        if (target) {
            target.classList.remove('ngx-drop-background--over');
        }
    }

    get acceptedFormats(): String {
        return this._acceptedFormats;
    }

    get fileReady(): boolean {
        return this._fileReady;
    }

    set fileReady(value: boolean) {
        this._fileReady = value;
    }

    get tableDataReady(): boolean {
        return this._tableDataReady;
    }

    set tableDataReady(value: boolean) {
        this._tableDataReady = value;
    }

    get tableReady(): boolean {
        return this._tableReady;
    }

    set tableReady(value: boolean) {
        this._tableReady = value;
    }

    get displayTable(): boolean {
        return this._displayTable;
    }

    set displayTable(value: boolean) {
        this._displayTable = value;
    }

    get isTableAutopop(): boolean {
        return this._isTableAutopop;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get isStep(): number {
        return this._isStep;
    }
}