<div class="organization-form-dialog-content">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-title>
    New Organization
    <mat-icon mat-dialog-close class="organization-form-dialog-close-icon">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div *ngIf="errors" class="error-message">
      <ng-container *ngFor="let error of errors">
        {{ error }}<br />
      </ng-container>
    </div>

    <div [formGroup]="organizationForm">
      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" cdkFocusInitial required />
          <mat-error *ngIf="organizationForm.get('name').invalid">{{ getNameError() }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Limit</mat-label>
          <input matInput type="number" formControlName="maximumExactMatches" required />
          <mat-error *ngIf="organizationForm.get('maximumExactMatches').invalid">{{ getLimitError() }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [disabled]="organizationForm.invalid" (click)="onSubmitOrganization()">
      Submit
    </button>
  </mat-dialog-actions>
</div>
