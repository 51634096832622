import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  isProcessed = false;

  private _errors = [];
  get errors() { return this._errors };

  checked: boolean = false;

  signupForm = this.formBuilder.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]]/*,
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-Z0-9!@#$%^&]*')
      ]
    ]*/
  });

  @ViewChild('firstNameInputElem', {read: ElementRef}) firstNameInputElem: ElementRef;

  constructor(
    private _router: Router,
    private formBuilder: FormBuilder,
    private _securityRepo: SecurityRepoService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if(this._securityRepo.isAuthenticated) {
      this._router.navigate(['/']);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.firstNameInputElem.nativeElement.focus();
      this._changeDetectorRef.detectChanges();
    }, 300);
  }

  getFirstNameError() {
    let fieldControl = this.signupForm.get('firstName');
    if(fieldControl.hasError('required')) {
      return 'First name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getLastNameError() {
    let fieldControl = this.signupForm.get('lastName');
    if(fieldControl.hasError('required')) {
      return 'Last name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getCompanyNameError() {
    let fieldControl = this.signupForm.get('companyName');
    if(fieldControl.hasError('required')) {
      return 'Company name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getEmailError() {
    let fieldControl = this.signupForm.get('email');
    if(fieldControl.hasError('required')) {
      return 'Email is required';
    }

    if(fieldControl.hasError('email')) {
      return 'Not a valid email';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  /*getPasswordError() {
    let fieldControl = this.signupForm.get('password');
    if(fieldControl.hasError('required')) {
      return 'Password is required';
    }

    if(fieldControl.hasError('minlength')) {
      return 'Password must be at least ' + fieldControl.errors.minlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('maxlength')) {
      return 'Password cannot be longer than ' + fieldControl.errors.maxlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('pattern')) {
      return 'Password contains invalid charcters. Allowed characters are a-z, A-Z, 0-9, and the following special characters: !@#$%^&';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }*/

  onSignup(event) {
    this._errors = [];
    this.isLoading = true;
    this._securityRepo.signup(this.signupForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let result = {
        errors: {
          detail: 'Signup request failed!'
        }
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        this.isProcessed = true;

        return;
      }

      let hasErrors = hasResult && result['errors'] && typeof result['errors'] === 'object';
      let errors = hasErrors ? result['errors'] : {};
      this.displayErrors(errors);
    });
  }

  private displayErrors(errors) {
    let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
    let hasInvalidFields = false;
    if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
      for(let violation of errors['validationErrors']['violations']) {
        let title = 'Unknown error';
        if(violation['title'] && typeof violation['title'] === 'string') {
          title = violation['title'];
        }

        let filedControl = this.getControlByName(violation['propertyPath']);
        if(filedControl) {
          filedControl.setErrors({apiError: title});
          filedControl.markAsTouched();
          hasInvalidFields = true;
        }
      }
    }

    let hasPasswordValidationError = typeof errors['passwordValidationError'] === 'object' && errors['passwordValidationError'];
    if(hasPasswordValidationError) {
      let title = 'Unknown error';
      if(errors['passwordValidationError']['title'] && typeof errors['passwordValidationError']['title'] === 'string') {
        title = errors['passwordValidationError']['title'];
      }

      let filedControl = this.getControlByName(errors['passwordValidationError']['propertyPath']);
      if(filedControl) {
        filedControl.setErrors({apiError: title});
        filedControl.markAsTouched();
        hasInvalidFields = true;
      }

      hasInvalidFields = true;
    }

    let errorMessage = 'An unknown error ocured! Please try again.';
    if(typeof errors['detail'] === 'string') {
      errorMessage = errors['detail'];
    }
    else if(hasInvalidFields) {
      errorMessage = 'Some fields have invalid values!';
    }

    this._errors.push(errorMessage);
  }

  private getControlByName(name) {
    switch(name) {
      case 'first_name':
      case 'firstName':
          return this.signupForm.get('firstName');
      case 'last_name':
      case 'lastName':
          return this.signupForm.get('lastName');
      case 'company_name':
      case 'companyName':
          return this.signupForm.get('companyName');
      case 'username':
      case 'email':
          return this.signupForm.get('email');
      case 'password':
          return this.signupForm.get('password');
    }

    return null;
  }
}
