import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsRepoService {
  static readonly urls = {
    organizations: environment.apiUrl + 'organizations',
    update: environment.apiUrl + 'organizations/update',
    create: environment.apiUrl + 'organizations/create'
  };

  constructor(private _httpClient: HttpClient) { }

  getOrganizations(): Observable<any> {
    return this._httpClient.get<any>(OrganizationsRepoService.urls.organizations);
  }

  updateOrganization(organization): Observable<any> {
    let params = { organization: organization };
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(OrganizationsRepoService.urls.update, content, options);
  }

  createOrganization(formValues): Observable<any> {
    if(!formValues || typeof formValues !== 'object') {
      return observableOf(false);
    }

    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(OrganizationsRepoService.urls.create, content, options);
  }
}
