<h2 class="mat-h2">Latest news & updates</h2>
<hr />
<ul>
  <li>All analyzed results from the 2020 REDW papers have been uploaded.</li>
  The database has {{ totalCount }} records as of {{ currentDate | date }}. COTS parts: {{ productsCount }}, and space and military parts: {{ smdProductsCount }}.
  <li>
    All registered users can enjoy limited access. <a routerLink="/signup">Subscribe</a> for unlimited use. 
    Request more information for <a routerLink="/inquiry">subscription</a>.
  </li>
</ul>
