import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductsListModel } from 'src/app/models/product.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsRepoService {

  constructor(private _httpClient: HttpClient) { }

  getSampleProducts(): Observable<ProductsListModel> {
    const requestUrl = environment.apiUrl + 'sample-products';

    return this._httpClient.get<ProductsListModel>(requestUrl);
  }

  getSampleProductById(id: number): Observable<any> {
    const href = environment.apiUrl + 'sample-products';
    const requestUrl = `${href}/${id}`;

    return this._httpClient.get<any>(requestUrl);
  }

  getProducts(sort: string, order: string, page: number, filters: any): Observable<ProductsListModel> {
    const href = environment.apiUrl + 'products';
    const requestUrl = `${href}?sort=${encodeURIComponent(sort)}&order=${order}&page=${page + 1}&filters=${encodeURIComponent(JSON.stringify(filters))}`;

    return this._httpClient.get<ProductsListModel>(requestUrl);
  }

  getFilters(): Observable<any> {
    const href = environment.apiUrl + 'products/filter/get/data';
    const requestUrl = `${href}`;

    return this._httpClient.get<ProductsListModel>(requestUrl);
  }

  getCount(): Observable<any> {
    const href = environment.apiUrl + 'products/count';
    const requestUrl = `${href}`;

    return this._httpClient.get<ProductsListModel>(requestUrl);
  }

  getProductsByParams(params): Observable<ProductsListModel> {
    const href = environment.apiUrl + 'products';
    const requestUrl = `${href}?params=${encodeURIComponent(JSON.stringify(params))}`;

    return this._httpClient.get<ProductsListModel>(requestUrl);
  }

  getProductById(id: number): Observable<any> {
    const href = environment.apiUrl + 'products';
    const requestUrl = `${href}/${id}`;

    return this._httpClient.get<any>(requestUrl);
  }

  uploadProductsCsv(file, action = ''): Observable<any> {
    const href = environment.apiUrl + 'products/upload-csv';
    const requestUrl = `${href}`;
    let formData = new FormData();
    formData.append('productsFile', file);

    return this._httpClient.post<any>(requestUrl, formData);
  }

  getAjaxUrl(): string {
    return environment.apiUrl + 'products';
  }

  uploadFile(file: File, relativePath: string, rowsToSkip: number): Observable<any> {
      const href = environment.apiUrl + 'products/upload-file';
      const requestUrl = `${href}`;
      let formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', relativePath);
      formData.append('skip', rowsToSkip.toString());

      return this._httpClient.post<any>(requestUrl, formData);
  }
}
