import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  private _isAuthenticated: boolean = false;
  get isAuthenticated() { return this._isAuthenticated };

  private _isAdmin: boolean = false;
  get isAdmin() { return this._isAdmin };

  private _displayName: string = '';
  get displayName() { return this._displayName };

  tooltipShowDelay = 400;
  get productsIconTooltipText() { return this._isAuthenticated ? 'Database' : 'Sample database'};

  @ViewChild('alertDialog', { static: true }) alertDialog: TemplateRef<any>;

  constructor(
    private _securityRepoService: SecurityRepoService,
    private _router: Router,
    public matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._isAuthenticated = this._securityRepoService.isAuthenticated;
    let user = this._securityRepoService.user;
    this.setUser(user);

    this._securityRepoService.authenticationChanged$.subscribe(authStatus => {
      this._isAuthenticated = authStatus;
    });

    this._securityRepoService.userChanged$.subscribe(user => {
      this.setUser(user);
    });
  }

  onLogin(event) {
    this._securityRepoService.redirectUrl = this._router.url;
    this._router.navigate(['/login']);
  }

  onLogout(event) {
    this._securityRepoService.logout()
    .subscribe(result => {
      if(result === true) {
        this._isAuthenticated = false;
        this._router.navigateByUrl('/login');
      }
      else {
        const dialogRef = this.matDialog.open(this.alertDialog);
      }
    });
  }

  private setUser(user) {
    this._isAdmin = false;
    this._displayName = '';
    if(user) {
      this._displayName = '  ' + user.firstName + ' ' + user.lastName;
      this._displayName = this._displayName.trim();
      if(!this._displayName) {
        this._displayName = user.username;
      }

      if(Array.isArray(user.roles)) {
        for(let i = 0; i < user.roles.length; i++) {
          if(user.roles[i] === 'ROLE_ADMIN') {
            this._isAdmin = true;
          }
        }
      }
    }
  }
}
