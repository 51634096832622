<div class="homePageWrapper">
  <div id="colOneWrapper">
    <div id="colOneContent">
      <mat-card class="home-card" [class.mat-elevation-z8]="true">
        <mat-card-header>
          <mat-card-title>Welcome to the Radiation Database!</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="!isAuthenticated">
            <a routerLink="/login">Login</a>
          </ng-container>
          <ng-container *ngIf="isAuthenticated">
            <p>Welcome {{ userFirstname }}! Proceed to the <a routerLink="/products">database</a>.</p>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
