<h2 mat-dialog-title>Choose product name column</h2>
<mat-dialog-content>
    <mat-select [(value)]="selected">
        <mat-option *ngFor="let name of data" value="{{name}}">{{name}}</mat-option>
    </mat-select>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close="{{selected}}">Confirm</button>
    <button mat-button mat-dialog-close="">Cancel</button>
</mat-dialog-actions>
