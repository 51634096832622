import { Component, OnInit} from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

import { AngularEditorConfig, UploadResponse } from '@kolkov/angular-editor';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { ProductsRepoService } from 'src/app/repositories/products-repo/products-repo.service';
import { NewsRepoService} from 'src/app/repositories/news-repo/news-repo.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { environment } from 'src/environments/environment';
import { httpInterceptorProviders } from 'src/app/shared/http-interceptors';
import { HttpClient, HttpEvent } from '@angular/common/http';




@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit {
  news: object[] = [];
  displayingNews: object[] = [];
  newsPerScroll: number = 5;
  currentAmountNews: number = 0;
  isLoading: boolean = true;
  isLoaded: boolean = false;

  currentDate = new Date();
  productsCount: any = '---';
  smdProductsCount: any = '---';
  totalCount: any = '---';

  showAdd: boolean = false;
  
  addText: string = '';
  addTitle: string = '';
  addTextError: string = '';
  addTitleError: string = '';

  editText: string = '';
  editTitle: string = '';
  editTextError: string = '';
  editTitleError: string = '';

  deleteError: string = '';

  selectedEditId: number = -1;
  
  

  EDITOR_CONFIG:AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: '',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [],
      uploadUrl: environment.apiUrl + 'image',
      uploadWithCredentials: true,
      sanitize: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
      [
        'customClasses',
        'link',
        'unlink'
      ]
    ]
  };


  constructor(private _securityRepoService: SecurityRepoService, 
              private _productsRepo: ProductsRepoService,
              private _newsRepo: NewsRepoService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) 
              {}

  ngOnInit(): void {
    this.loadProductsCount();
    this.getAllNews();
  }

  public getAllNews() {
    this._newsRepo.getNews().subscribe(result => {
      this.isLoaded = true;
      this.isLoading = false;
      if (result['news'].length > 0) {
        this.news = result['news'];
        this.addNewsToDisplay();
      }
    });
  }

  public loadProductsCount() {
    this._productsRepo.getCount()
    .pipe(catchError((err) => {
      this.productsCount = '---';

      return observableOf(false);
    }))
    .subscribe(result => {
      let hasResult = result && typeof result === 'object';
      if(hasResult && typeof result['productsCount'] === 'number') {
        this.totalCount = result['total'];
        this.productsCount = result['productsCount'];
        this.smdProductsCount = result['smdProductsCount'];
      }
    });
  }

  public onEdit(id: number, title: string, text: string) {
    this.editTextError = '';
    this.editText = text;
    this.editTitleError = '';
    this.editTitle = title;
    let titleElem = <HTMLInputElement>document.getElementById('editTitle' + id);
    if (titleElem) {
      titleElem.value = title;
    }
    let query = '[ng-reflect-id="value"]';
    let re = /value/gi;
    query = query.replace(re,'textEdit' + id);
    let angularEditor = document.querySelector(query);
    if (angularEditor) {
      let textArea = angularEditor.querySelector('.angular-editor-textarea');
      if (textArea) {
        textArea.innerHTML = text;
      }
    }
    if (!this.isCurrentlySelectedEdit(id)) {
      this.selectedEditId = id;
      return
    }
    this.selectedEditId = -1;
  }

  public onAdd() {
    this.showAdd = !this.showAdd;
  }

  public onEditSubmit(id: number) {
    let titleElem = <HTMLInputElement>document.getElementById('editTitle' + id);
    if (titleElem) {
      this.editTitle = titleElem.value;
    }

    this.editTitleError = (this.editTitle.length < 1)? 'Title field is empty!' : '';
    this.editTextError = (this.editText.length < 1)? 'Text field is empty!' : '';

    if (this.editTextError.length > 0 || this.editTitleError.length > 0) {
      return;
    }

    let news = {
      id: id,
      title: this.editTitle,
      text: this.editText
    }

    this.editText = '';
    this.editTitle = '';
    this.isLoading = true;
    this._newsRepo.editNews(news).subscribe(result => {
        this.isLoading = false;
        if (!result['success']) {
          this.displayErrorSnackBar(result['message']);
        } else {
          this.selectedEditId = -1;
          this.displayingNews.forEach(item => {
              if (item['id'] === news.id) {
                item['text'] = news.text;
                item['title'] = news.title;
              }
          });
        }
    });
  }

  public onEditTextChange() {
    this.editTextError = '';
  }

  public onEditTitleChange() {
    this.editTitleError = '';
  }

  public onAddSubmit() {
    let title = <HTMLInputElement>document.getElementById('addTitle');
    if (title) {
      this.addTitle = title.value
    }
    
    this.addTextError = (this.addText.length < 1)? 'Empty text field!' : '';
    this.addTitleError = (this.addTitle.length < 1)? 'Emtpy title field!' : '';
    
    if (this.addTitleError.length > 0 || this.addTextError.length > 0) {
      return;
    }

    let news = {
      title: this.addTitle,
      text: this.addText
    };
    
    this.addText = '';
    let query = '[name="addText"]';
    let angularEditor = document.querySelector(query);
    if (angularEditor) {
      let textArea = angularEditor.querySelector('.angular-editor-textarea');
      if (textArea) {
        textArea.innerHTML = '';
      }
    }

    title.value = this.addTitle = '';
    
    this.isLoading = true;
    this._newsRepo.createNews(news).subscribe(result => {
      this.isLoading = false;
      if (!result['success']) {
        this.displayErrorSnackBar(result['message']);
      } else {
        this.showAdd = false;
        this.displayingNews.unshift(result['created']);
      }
    });
  }

  public onAddTextChange() {
    this.addTextError = '';
  }

  public onAddTitleChange() {
    this.addTitleError = '';
  }

  public onDelete(id: number) {
    let deleteDialog = this.dialog.open(DeleteDialogComponent);
    deleteDialog.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.isLoading = true;
        this._newsRepo.deleteNews(id).subscribe(result => {
          this.isLoading = false;
          if (!result['success']) {
            this.displayErrorSnackBar('message');
          } else {
            this.displayingNews.forEach((item,index) => {
              if (item['id'] === id) {
                this.displayingNews.splice(index,1);
              }
            });
          }
        });
      }
    });
  }

  public isCurrentlySelectedEdit (id: number): boolean {
    if (id === this.selectedEditId) {
      return true;
    }
    return false;
  }

  public displayErrorSnackBar(message: string) {
    this.snackBar.open(message, "", {
        duration: 3000,
        panelClass: ['red-snackbar'],
        verticalPosition: 'top',
        horizontalPosition: 'right'
    });
}

  public addNewsToDisplay() {
    let to = this.newsPerScroll + this.currentAmountNews;
    this.displayingNews = this.displayingNews.concat(this.news.slice(this.currentAmountNews, to));
    this.currentAmountNews += 5;
  }

  public onScrolled() {
    if (this.news.length > this.currentAmountNews) {
      this.addNewsToDisplay();
    }
  }

  get isAdmin() {
    return this._securityRepoService.isAdmin;
  }

  get getShowAdd() {
    return this.showAdd;
  }

  get getAddTextError() {
    return this.addTextError;
  }

  get getEditTextError() {
    return this.editTextError;
  }

  get getMyEditorConfig() {
    return this.EDITOR_CONFIG;
  }

  set setAddText(str: string) {
    this.addText = str;
  }

  set setEditText(str: string) {
    this.editText = str;
  }

  get getAddTitleError() {
    return this.addTitleError;
  }

  get getEditTitleError() {
    return this.editTitleError
  }
}