<!-- Toolbar -->
<mat-toolbar #headerToolbarDiv class="header-toolbar" role="banner">
  <div>
    <a mat-icon-button class="toolbar-item" routerLink="/" matTooltip="Return home" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>home</mat-icon>
    </a>
    <a mat-icon-button class="toolbar-item" routerLink="/about" matTooltip="About" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>info</mat-icon>
    </a>
    <a mat-icon-button class="toolbar-item" routerLink="/terms" matTooltip="Terms and conditions" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>list_alt</mat-icon>
    </a>
    <a mat-icon-button class="toolbar-item" routerLink="/products" [matTooltip]="productsIconTooltipText" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>web</mat-icon>
    </a>
    <a mat-icon-button class="toolbar-item" routerLink="/inquiry" matTooltip="Inquiry" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>contact_mail</mat-icon>
    </a>
    <a mat-icon-button class="toolbar-item" routerLink="/news" matTooltip="News & updates" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>feed</mat-icon>
    </a>
  </div>
  <div>
    <button mat-icon-button [matMenuTriggerFor]="toolsMenu" class="toolbar-item" matTooltip="More tools" [matTooltipShowDelay]="tooltipShowDelay"><mat-icon>build</mat-icon></button>
    <button *ngIf="displayName" mat-button [matMenuTriggerFor]="userMenu" class="toolbar-item" matTooltip="Account info" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>account_box</mat-icon>
      {{ displayName }}
    </button>
    <button *ngIf="!displayName" mat-icon-button [matMenuTriggerFor]="userMenu" class="toolbar-item" matTooltip="Account info" [matTooltipShowDelay]="tooltipShowDelay">
      <mat-icon>account_box</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-menu #toolsMenu="matMenu" xPosition="before">
  <a mat-menu-item href="https://apps-eeeradiation.com/see-analysis/" target="_blank">SEE Analysis</a>
  <a mat-menu-item href="https://apps-eeeradiation.com/survival-model/" target="_blank">SEL Prediction Model</a>
  <ng-container *ngIf="isAdmin">
    <a mat-menu-item routerLink="/users">Users</a>
    <a mat-menu-item routerLink="/organizations">Organizations</a>
  </ng-container>
</mat-menu>

<mat-menu #userMenu="matMenu" xPosition="before">
  <a *ngIf="!isAuthenticated" mat-menu-item routerLink="/signup">Signup</a>
  <button *ngIf="!isAuthenticated" mat-menu-item (click)="onLogin($event)">Login</button>

  <a *ngIf="isAuthenticated" mat-menu-item routerLink="/user-profile">Profile</a>
  <button *ngIf="isAuthenticated" mat-menu-item (click)="onLogout($event)">Logout</button>
</mat-menu>

<ng-template #alertDialog>
  <div mat-dialog-title>Logout Error</div>
  <div mat-dialog-content>
    Failed to sign you out! Please try again.<br />
    If the problem persists, close your browser.
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button mat-dialog-close color="primary" cdkFocusInitial>Ok</button>
  </mat-dialog-actions>
</ng-template>