import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { PartRequestsRepoService } from 'src/app/repositories/part-requests-repo/part-requests-repo.service';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { PartRequestsFormDialogComponent } from './part-requests-form-dialog/part-requests-form-dialog.component';

@Component({
  selector: 'app-part-requests',
  templateUrl: './part-requests.component.html',
  styleUrls: ['./part-requests.component.scss']
})
export class PartRequestsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isLoading: boolean = false;
  isNewPartRequestLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  private _isTableCreated: boolean = false;
  private _tableWrapperHeight = 0;

  @ViewChild('partRequestsTableWrapper', {read: ElementRef}) partRequestsTableWrapper: ElementRef;
  @ViewChild('partRequestsTable', {read: DipTabulatorComponent}) partRequestsTable: DipTabulatorComponent;

  partRequestForm = this._formBuilder.group({
    partNumber: ['', [Validators.required]],
    manufacturer: [''],
    function: [''],
    dataType: [''],
    notes: ['']
  });

  constructor(
    private _securityRepoService: SecurityRepoService,
    private _partRequestsRepo: PartRequestsRepoService,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._tableWrapperHeight = this.partRequestsTableWrapper.nativeElement.offsetHeight;
    this.partRequestsTable.createTable(this.getTableConfig());
    this._isTableCreated = true;
  }

  ngAfterViewChecked() {
    let height = this.partRequestsTableWrapper.nativeElement.offsetHeight;
    if(this._tableWrapperHeight !== height) {
      this._tableWrapperHeight = height;
      if(this._isTableCreated) {
        this.partRequestsTable.table.redraw(true);
      }
    }
  }

  private getTableConfig() {
    let partRequestsRepo = this._partRequestsRepo;
    let ajaxRequestFunc = (url, config, params) => {
      return new Promise(function(resolve, reject) {
        partRequestsRepo.getPartRequests(params)
        .pipe(catchError(() => {
          reject();
          return observableOf([]);
        }))
        .subscribe(data => {
          resolve(data);

          let configs = null;
          if(data && typeof data === 'object' && data['configs']) {
            configs = data['configs'];
          }
        });
      });
    }

    let tableConfig = {
      height: '100%',
      layout: 'fitColumns', //fit columns to width of table (optional)
      responsiveLayout: true,
      resizableRows: true,
      columns: this.getColumnDefs(),
      placeholder: 'No data to display!',
      ajaxURL: partRequestsRepo.getAjaxUrl(),
      ajaxConfig: 'GET',
      ajaxRequestFunc: ajaxRequestFunc,
      ajaxFiltering: true,
      ajaxSorting: true,
      pagination: 'remote',
      paginationSize: 20,
      paginationSizeSelector: true,
      paginationDataReceived: { 'data': 'items' },
    };

    return tableConfig;
  }

  private getColumnDefs() {
    let hasUserField = this._securityRepoService.isAdmin ? true : false;
    let columnDefs = [
      { title: "ID",           field: "id",           visible: false,        headerSort: true, headerSortTristate: true },
      { title: "Part Number",  field: "part_number",                         headerSort: true, headerSortTristate: true },
      { title: "Manufacturer", field: "manufacturer",                        headerSort: true, headerSortTristate: true },
      { title: "Function",     field: "function",                            headerSort: true, headerSortTristate: true },
      { title: "Data Type",    field: "data_type",                           headerSort: true, headerSortTristate: true },
      { title: "User",         field: "user",         visible: hasUserField, headerSort: true, headerSortTristate: true,
        formatter: function(cell) {
          let user = cell.getValue();
          let formattedValue = '';
          if(user && typeof user === 'object') {
            formattedValue = user.first_name + ' ' + user.last_name + '(' + user.username + ')';
          }

          return formattedValue;
        }
      }
    ];

    return columnDefs;
  }

  onMakeNewPartRequest(event) {
    const dialogRef = this._matDialog.open(PartRequestsFormDialogComponent);
  }
}
