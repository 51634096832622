import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportRepoService {

  constructor(private _httpClient: HttpClient) { }

  createInquiry(params): Observable<any> {
    const href = environment.apiUrl + 'support/inquiries/create';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }
}
