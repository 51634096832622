import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UsersRepoService } from 'src/app/repositories/users-repo/users-repo.service';
import { OrganizationsRepoService } from 'src/app/repositories/organizations-repo/organizations-repo.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  isLoading: boolean = false;
  isLoadingStatistics: boolean = false;

  private _user;
  get user() { return this._user; }

  private _organizations = [];
  get organizations() { return this._organizations; }

  private _hasOrganizationsLoadError: boolean = false;
  get hasOrganizationsLoadError() { return this._hasOrganizationsLoadError; }

  private _statistics: object = null;
  get statistics() { return this._statistics; }

  private _hasStatisticsLoadError: boolean = false;
  get hasStatisticsLoadError() { return this._hasStatisticsLoadError; }

  private _isBasicMember: boolean = false;
  get isBasicMember() { return this._isBasicMember; }

  private _isProMember: boolean = false;
  get isProMember() { return this._isProMember; }

  get isMember() { return this._isBasicMember || this._isProMember ? true : false; }

  private _trialEndDate: Date = null;
  get trialEndDate() { return this._trialEndDate; }

  private _isInTrialPeriod: boolean = false;
  get isInTrialPeriod() { return this._isInTrialPeriod; }

  private _isInFreeSearchPeriod: boolean = false;
  get isInFreeSearchPeriod() { return this._isInFreeSearchPeriod; }

  get isInFreeSearchOrTrialPeriod() { return this._isInFreeSearchPeriod || this._isInTrialPeriod ? true : false; }

  get isActive() {
    if(this._user && typeof this._user === 'object') {
      if(this._user.account_status && typeof this._user.account_status === 'string') {
        return (this._user.account_status.toLowerCase() == 'active');
      }
    }

    return false;
  }

  private _isInTokenPeriod: boolean = false;
  get isInTokenPeriod() { return this._isInTokenPeriod; }

  private _tokenEndDate: Date = null;
  get tokenEndDate() { return this._tokenEndDate; }

  private _hasPassword: boolean = true;
  get hasPassword() { return this._hasPassword; }

  private _ip: string = '';
  get ip() { return this._ip; }

  @ViewChild('confirmationDialog', { static: true }) confirmationDialog: TemplateRef<any>;
  @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;

  userAccountForm = this.formBuilder.group({
    username: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    organization: [''],
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-Z0-9!@#$%^&]*')
      ]
    ]
  });

  membershipDurationDatesForm: FormGroup;
  private _initialMembershipStartDate: Date;
  private _initialMembershipEndDate: Date;
  get initialMembershipStartDate() {
    return this._initialMembershipStartDate == null;
  }

  private _isMembershipDurationLoading: boolean = false;
  get isMembershipDurationLoading() {
    return this._isMembershipDurationLoading;
  }

  statisticsDatesForm: FormGroup;
  private _initialStartDate: Date;
  private _initialEndDate: Date;

  loginDurationDatesForm: FormGroup;
  private _initialLDStartDate: Date;
  private _initialLDEndDate: Date;

  private _loginDuration: string = '---';
  get loginDuration() {
    return this._loginDuration;
  }
  private _isLDLoading: boolean = false;
  get isLDLoading() {
    return this._isLDLoading;
  }

  constructor(
    private formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _usersRepo: UsersRepoService,
    private _organizationsRepo: OrganizationsRepoService,
    private matDialog: MatDialog
  )
  {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();


    this.statisticsDatesForm = this.formBuilder.group({
      start: [new Date(year, month, 1), [Validators.required]],
      end: [today, [Validators.required]],
    });

    this.loginDurationDatesForm = this.formBuilder.group({
      start: [new Date(year, month, 1), [Validators.required]],
      end: [today, [Validators.required]],
    });


    this._initialStartDate = new Date(this.statisticsDatesForm.get('start').value);
    this._initialEndDate = new Date(this.statisticsDatesForm.get('end').value);

    this._initialLDStartDate = new Date(this.loginDurationDatesForm.get('start').value);
    this._initialLDEndDate = new Date(this.loginDurationDatesForm.get('end').value);
  }

  ngOnInit(): void {
    this._activatedRoute.paramMap.subscribe(paramMap => {
      let userId = Number(paramMap.get('id'));
      if(Number.isInteger(userId)) {
        this.getUserDetail(userId);
        this.fetchStatistics(userId, this.getNormalizedStatsStartDate(), this.getNormalizedStatsEndDate());
      }
    });

    this.fetchOrganizations();
  }

  private getUserDetail(id: number) {
    this.isLoading = true;
    this._usersRepo.getUserDetail(id)
    .pipe(catchError(() => {
      return observableOf(false);
    }))
    .subscribe(data => {
      if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
        this.setUser(data['user']);
      }
      else {
        this.setUser(null);
      }

      this.isLoading = false;
    });
  }

  getMembershipType() {
    if(this._isBasicMember) {
      return 'Basic';
    }

    if(this._isProMember) {
      return 'Pro';
    }

    return '';
  }

  getUsernameError() {
    let fieldControl = this.userAccountForm.get('username');
    if(fieldControl.hasError('required')) {
      return 'Username is required';
    }

    return '';
  }

  getStatisticsStartDateError() {
    let fieldControl = this.statisticsDatesForm.get('start');
    if(fieldControl.hasError('required')) {
      return 'Start date is required!';
    }

    if(fieldControl.hasError('matStartDateInvalid')) {
      return 'Start date is invalid!';
    }

    return '';
  }

  getStatisticsEndDateError() {
    let fieldControl = this.statisticsDatesForm.get('end');
    if(fieldControl.hasError('required')) {
      return 'End date is required!';
    }

    if(fieldControl.hasError('matEndDateInvalid')) {
      return 'End date is invalid!';
    }

    return '';
  }

  getLDStartDateError() {
    let fieldControl = this.loginDurationDatesForm.get('start');
    if(fieldControl.hasError('required')) {
      return 'Start date is required!';
    }

    if(fieldControl.hasError('matStartDateInvalid')) {
      return 'Start date is invalid!';
    }

    return '';
  }

  getLDEndDateError() {
    let fieldControl = this.loginDurationDatesForm.get('end');
    if(fieldControl.hasError('required')) {
      return 'End date is required!';
    }

    if(fieldControl.hasError('matEndDateInvalid')) {
      return 'End date is invalid!';
    }

    return '';
  }

  getMembershipStartDateError() {
    let fieldControl = this.membershipDurationDatesForm.get('start');
    if(fieldControl.hasError('required')) {
      return 'Start date is required!';
    }

    if(fieldControl.hasError('matStartDateInvalid')) {
      return 'Start date is invalid!';
    }

    return '';
  }

  getMembershipEndDateError() {
    let fieldControl = this.membershipDurationDatesForm.get('end');
    if(fieldControl.hasError('required')) {
      return 'End date is required!';
    }

    if(fieldControl.hasError('matEndDateInvalid')) {
      return 'End date is invalid!';
    }

    return '';
  }

  onActivateUserAccount(id) {
    let config = {
      data: {
        id: id,
        msg: 'Are you sure you want to activate user account with ID ' + id + '?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.activateAccount({id: id})
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to activate user account!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onDisableUserAccount(id) {
    let config = {
      data: {
        id: id,
        msg: 'Are you sure you want to disable user account with ID ' + id + '?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.disableAccount({id: id})
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to disable user account!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onUpdateUserAccount() {
    let organization = null;
    let orgID = this.normalizeOrganizationValue(this.userAccountForm.get('organization').value);
    if(orgID) {
      organization = {
        id: this.normalizeOrganizationValue(this.userAccountForm.get('organization').value)
      }
    }

    let fieldControl = this.userAccountForm.get('username');
    let updatedUser = {
      id: this._user.id,
      username: fieldControl.value,
      organization: organization
    };

    let config = {
      data: {
        id: updatedUser.id,
        msg: 'Are you sure you want to update username?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.updateAccount(updatedUser)
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to update username!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onChangeMembership() {
    let membershipType = null;
    if(this._isBasicMember) {
      membershipType = 'pro';
    }
    else if(this._isProMember) {
      membershipType = 'basic';
    }
    else {
      return;
    }

    let config = {
      data: {
        msg: 'Are you sure you want to change membership for ' + this._user.username + '?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.changeMembership({id: this._user.id, membership_type: membershipType})
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to change membership!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onResetTrialStartDate(event) {
    let config = {
      data: {
        msg: 'Are you sure you want to reset trial period start date?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.resetTrialStartDate({id: this._user.id, })
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to reset the start date of trial period!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onEndTrial(event) {
    let config = {
      data: {
        msg: 'Are you sure you want to end trial period?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.endTrial({id: this._user.id, })
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to end the trial period!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onResetFreeSearchStartDate(event) {
    let config = {
      data: {
        msg: 'Are you sure you want to reset free search period start date?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.resetFreeSearchStartDate({id: this._user.id, })
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to reset the start date of free search period!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  onResetFreeSearchCounters(event) {
    let config = {
      data: {
        msg: 'Are you sure you want to reset free search counters?'
      }
    };

    const dialogRef = this.matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this._usersRepo.resetFreeSearchCounters({id: this._user.id, })
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['user'] && typeof data['user'] === 'object') {
            this.setUser(data['user']);
          }
          else {
            let config = {
              data: {
                msg: 'Failed to reset the counters of free search period!'
              }
            };

            this.matDialog.open(this.errorDialog, config);
          }
        });
      }
    });
  }

  private setUser(user) {
    this._isBasicMember = false;
    this._isProMember = false;
    this._user = user;
    let usernameCtrl = this.userAccountForm.get('username');
    let organizationCtrl = this.userAccountForm.get('organization');
    if(user) {
      this.checkPassword();

      usernameCtrl.setValue(this._user.username);

      let orgID = this._user && this._user.organization ? this._user.organization.id : '';
      orgID = this.normalizeOrganizationValue(orgID);
      organizationCtrl.setValue(orgID);

      if(user['membership'] && typeof user['membership'] === 'object') {
        this._isBasicMember = user['membership']['isTypeBasic'] === true ? true : false;
        this._isProMember = user['membership']['isTypePro'] === true ? true : false;

        this._trialEndDate = this.parseDate(user['membership']['trialEndDate']);
        this._isInTrialPeriod = user['membership']['isInTrialPeriod'] === true ? true : false;
        this._isInFreeSearchPeriod = user['membership']['isInFreeSearchPeriod'] === true ? true : false;
      }

      let membershipStartDate = null;
      let membership_end_date = null;

      if (this._user['membership'] && this._user['membership'].membership_start_date) {
        membershipStartDate = this._user['membership'].membership_start_date;
        let endDate = new Date(this._user['membership'].membership_end_date);
        endDate.setDate(endDate.getDate()-1);
        membership_end_date = endDate;
      }

      this.membershipDurationDatesForm = this.formBuilder.group({
        start: membershipStartDate,
        end: membership_end_date,
      });

      this._initialMembershipStartDate = this.membershipDurationDatesForm.get('start').value ? new Date(this.membershipDurationDatesForm.get('start').value) : null;
      this._initialMembershipEndDate = this.membershipDurationDatesForm.get('end').value ? new Date(this.membershipDurationDatesForm.get('end').value) : null;
    }
    else {
      usernameCtrl.setValue('');
      organizationCtrl.setValue('');
    }
  }

  getRoleTitle(role) {
    return this._usersRepo.getRoleTitle(role);
  }

  parseDate(strDate) {
    if(!strDate || typeof strDate !== 'string' || strDate.length < 0) {
      return null;
    }

    let parsedDate: any = new Date(strDate);
    if(!isNaN(parsedDate)) {
      if(parsedDate instanceof Date) {
        return parsedDate;
      }
    }

    return null;
  }

  private fetchOrganizations() {
    this._organizationsRepo.getOrganizations()
    .pipe(catchError(() => {
      return observableOf(false);
    }))
    .subscribe(data => {
      if(data && typeof data === 'object' && data['organizations'] && Array.isArray(data['organizations'])) {
        this._organizations = data['organizations'];
        this._hasOrganizationsLoadError = false;
      }
      else {
        this._organizations = [];
        this._hasOrganizationsLoadError = true;
      }
    });
  }

  private fetchStatistics(userId: number, startDate: Date = null, endDate: Date = null) {
    this._hasStatisticsLoadError = false;
    this.isLoadingStatistics = true;
    this._usersRepo.getStatistics(userId, startDate, endDate)
    .pipe(catchError(() => {
      return observableOf(false);
    }))
    .subscribe(data => {
      if(data && typeof data === 'object' && data['statistics'] && typeof data['statistics'] === 'object') {
        this.setStatistics(data['statistics']);
      }
      else {
        this.setStatistics(null);
        this._hasStatisticsLoadError = true;
      }

      this.isLoadingStatistics = false;
    });
  }

  private fetchLoginDuration(userId: number, startDate: Date, endDate: Date) {
    this._isLDLoading = true;
    let params = {
      userId: userId,
      fromDate: startDate,
      toDate: endDate
    }

    this._usersRepo.getLoginDuration(params).subscribe(result => {
      this._isLDLoading = false;
      if (result['success']) {
        this._loginDuration = result['result'];
      }
    });
  }

  private setMembershipDuration(userId: number, startDate: Date, endDate: Date, organizationId: number) {
    this._isMembershipDurationLoading = true;
    let params = {
      userId: userId,
      fromDate: startDate,
      toDate: endDate,
      organizationId: organizationId
    }

    this._usersRepo.setMembershipDuration(params).subscribe(result => {
      this._isMembershipDurationLoading = false;
    });
  }

  private setStatistics(newStats) {
    if(!newStats || typeof newStats !== 'object') {
      this._statistics = null;
      return;
    }

    this._statistics = newStats;
  }

  getOrganizationInitialValue() {
    let orgID = this._user && this._user.organization ? this._user.organization.id : null;
    return this.normalizeOrganizationValue(orgID);
  }

  isOrganizationValueChanged() {
    let currentValue = this.normalizeOrganizationValue(this.userAccountForm.get('organization').value);
    if(currentValue === this.getOrganizationInitialValue()) {
      return false;
    }

    return true;
  }

  isDateRangeChanged() {
    if(!this.isDateEqual(this._initialStartDate, this.statisticsDatesForm.get('start').value)) {
      return true;
    }

    if(!this.isDateEqual(this._initialEndDate, this.statisticsDatesForm.get('end').value)) {
      return true;
    }

    return false;
  }

  isLDDateRangeChanged() {
    if(!this.isDateEqual(this._initialLDStartDate, this.loginDurationDatesForm.get('start').value)) {
      return true;
    }

    if(!this.isDateEqual(this._initialLDStartDate, this.loginDurationDatesForm.get('end').value)) {
      return true;
    }

    return false;
  }

  updateMembershipDate() {
    if (this.membershipDurationDatesForm.get('start').value != null) {
        this._initialMembershipStartDate = this.membershipDurationDatesForm.get('start').value;
    }

    if (this.membershipDurationDatesForm.get('end').value != null) {
      this._initialMembershipEndDate = this.membershipDurationDatesForm.get('end').value;
    }
  }

  private isDateEqual(d1, d2) {
    if(d1 == null && d2 == null) {
      return true;
    }

    if(!(d1 instanceof Date) || !(d2 instanceof Date)) {
      return false;
    }

    if(d1.getTime() === d2.getTime()) {
      return true;
    }

    return false;
  }

  onApplyDateRangeFilter() {
    if(!this._user) {
      return;
    }

    this.fetchStatistics(this._user.id, this.getNormalizedStatsStartDate(), this.getNormalizedStatsEndDate());
    this._initialStartDate = new Date(this.statisticsDatesForm.get('start').value);
    this._initialEndDate = new Date(this.statisticsDatesForm.get('end').value);
  }

  onApplyLDDateRangeFilter() {
    if(!this._user) {
      return;
    }

    this.fetchLoginDuration(this._user.id, this.getNormalizedLDStartDate(), this.getNormalizedLDEndDate());
    this._initialLDStartDate = new Date(this.loginDurationDatesForm.get('start').value);
    this._initialLDEndDate = new Date(this.loginDurationDatesForm.get('end').value);
  }

  onApplyMembershipDateRangeFilter(forOrganization = false) {
    if(!this._user) {
      return;
    }
    let organizationId = 0;
    
    if (forOrganization) {
      organizationId = this.user.organization.id;
    }

    this.setMembershipDuration(this._user.id, this.getNormalizedMembershipStartDate(), this.getNormalizedMembershipEndDate(), organizationId);
    this._initialMembershipStartDate = new Date(this.membershipDurationDatesForm.get('start').value);
    this._initialMembershipEndDate = new Date(this.membershipDurationDatesForm.get('end').value);
  }

  onReloadStatistics() {
    if(!this._user) {
      return;
    }

    this.fetchStatistics(this._user.id, this.getNormalizedStatsStartDate(), this.getNormalizedStatsEndDate());
  }

  private normalizeOrganizationValue(id) {
    let orgID = parseInt(id);
    if(isNaN(orgID)) {
      return '';
    }

    return orgID;
  }

  private getNormalizedMembershipStartDate() {
    let startDate: Date = new Date(this.membershipDurationDatesForm.get('start').value);
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  }

  private getNormalizedMembershipEndDate() {
    let endDate: Date = new Date(this.membershipDurationDatesForm.get('end').value);
    endDate.setHours(23, 0, 59, 999);

    return endDate;
  }

  private getNormalizedStatsStartDate() {
    let startDate: Date = new Date(this.statisticsDatesForm.get('start').value);
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  }

  private getNormalizedLDStartDate() {
    let startDate: Date = new Date(this.loginDurationDatesForm.get('start').value);
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  }

  private getNormalizedStatsEndDate() {
    let endDate: Date = new Date(this.statisticsDatesForm.get('end').value);
    endDate.setHours(23, 59, 59, 999);

    return endDate;
  }

  private getNormalizedLDEndDate() {
    let startDate: Date = new Date(this.loginDurationDatesForm.get('end').value);
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  }

  private getIsTokenExpired() {
    let username = this._user.email;

    if (username.length > 0) {
      let params = {
        username: username
      };
      this._usersRepo.getIsTokenExpired(params).subscribe(result => {
        if (result['success']) {
          this._isInTokenPeriod = result['result'];
          if (result['result']) {
            this._tokenEndDate = result['date'];
          }
        }
      });
    }
  }

  public onRegenToken() {
    let username = this._user.email;

    if (username.length > 0) {
      let params = {
        username: username
      };
      this._usersRepo.regenToken(params).subscribe(result => {
        if (result['success']) {
          this._isInTokenPeriod = true;
          this._tokenEndDate = result['date'];
        }
      })
    }
  }

  private checkPassword() {
    let username = this._user.email;

    if (username.length > 0) {
      let params = {
        username: username
      };
      this._usersRepo.hasPassword(params).subscribe(result => {
        if (result['success']) {
          this._hasPassword = result['result'];
          if (!this._hasPassword) {
            this.getIsTokenExpired();
          }
        }
      })
    }
  }
}
