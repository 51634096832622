<div class="part-request-form-dialog-content">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-title>
    New Part Request
    <mat-icon mat-dialog-close class="part-request-form-dialog-close-icon">close</mat-icon>
  </div>
  <div mat-dialog-content>
    <div *ngIf="errors" class="error-message">
      <ng-container *ngFor="let error of errors">
        {{ error }}<br />
      </ng-container>
    </div>

    <div [formGroup]="partRequestForm">
      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Part Number</mat-label>
          <input matInput type="text" formControlName="partNumber" cdkFocusInitial required />
          <mat-error *ngIf="partRequestForm.get('partNumber').invalid">{{ getPartNumberError() }}</mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Manufacturer</mat-label>
          <input matInput type="text" formControlName="manufacturer" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Function</mat-label>
          <input matInput type="text" formControlName="function" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Data Type</mat-label>
          <input matInput type="text" formControlName="dataType" />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field floatLabel="auto" appearance="standard">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [disabled]="partRequestForm.invalid" (click)="onSubmitPartRequest()">
      Submit
    </button>
  </mat-dialog-actions>
</div>
