import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';

import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UsersComponent } from './pages/users/users.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { ProductsComponent } from './pages/products/products.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PasswordResetRequestComponent } from './pages/password-reset-request/password-reset-request.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { NewsComponent } from './pages/news/news.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CreatePasswordComponent } from './pages/signup/create-password/create-password.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: {
      title: 'Home'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    pathMatch: 'full',
    data: {
      title: 'About'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: {
      title: 'Login'
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    pathMatch: 'full',
    data: {
      title: 'Signup'
    }
  },
  {
    path: 'password-reset-request',
    component: PasswordResetRequestComponent,
    pathMatch: 'full',
    data: {
      title: 'Password Reset Request'
    }
  },
  {
    path: 'password-reset/:resetToken',
    component: PasswordResetComponent,
    pathMatch: 'full',
    data: {
      title: 'Password Reset'
    }
  },
  {
    path: 'users',
    component: UsersComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      title: 'Users'
    }
  },
  {
    path: 'users/:id',
    component: UserDetailComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      title: 'User Detail'
    }
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    pathMatch: 'full',
    data: {
      title: 'User Profile'
    }
  },
  {
    path: 'organizations',
    component: OrganizationsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      title: 'Organizations'
    }
  },
  {
    path: 'products',
    component: ProductsComponent,
    pathMatch: 'full',
    data: {
      title: 'Products'
    }
  },
  {
    path: 'inquiry',
    component: InquiryComponent,
    pathMatch: 'full',
    data: {
      title: 'Inquiry'
    }
  },
  {
    path: 'news',
    component: NewsComponent,
    pathMatch: 'full',
    data: {
      title: 'News & updates'
    }
  },
  {
    path: 'terms',
    component: TermsComponent,
    pathMatch: 'full',
    data: {
      title: 'Terms and Conditions'
    }
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent,
    pathMatch: 'full',
    data: {
      title: 'Create password for user'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'Not Found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
