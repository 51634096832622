<div id="signupPageWrapper">
  <div id="colOneWrapper">
    <div id="colOneContent">
      <div class="signup-loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card class="signup-card" [class.mat-elevation-z8]="true">
        <mat-card-header>
          <mat-card-title *ngIf="!isProcessed">Welcome to the Radiation Database!</mat-card-title>
          <mat-card-title *ngIf="isProcessed">Signup Complete</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="!isProcessed">Please sign up</ng-container>
        <mat-card-content [formGroup]="signupForm">
          <div *ngIf="errors" class="error-message">
            <ng-container *ngFor="let error of errors">
              {{ error }}<br />
            </ng-container>
          </div>

          <ng-container *ngIf="isProcessed">
            Thank you for becoming a member. We will review your application and send you an email with instructions on how to access your account.
          </ng-container>

          <ng-container *ngIf="!isProcessed">
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>First Name</mat-label>
              <input #firstNameInputElem matInput type="text" formControlName="firstName" required />
              <mat-error *ngIf="signupForm.get('firstName').invalid">{{ getFirstNameError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" formControlName="lastName" required />
              <mat-error *ngIf="signupForm.get('lastName').invalid">{{ getLastNameError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Company</mat-label>
              <input matInput type="text" formControlName="companyName" required />
              <mat-error *ngIf="signupForm.get('companyName').invalid">{{ getCompanyNameError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>E-mail (Please use your company e-mail address)</mat-label>
              <input matInput type="text" formControlName="email" required />
              <mat-error *ngIf="signupForm.get('email').invalid">{{ getEmailError() }}</mat-error>
            </mat-form-field>
            <!--<mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password" required />
              <mat-error *ngIf="signupForm.get('password').invalid">{{ getPasswordError() }}</mat-error>
            </mat-form-field>-->
            <section>
              <mat-checkbox [(ngModel)]="checked" color="primary" [ngModelOptions]="{standalone: true}" ngDefaultControl>i have read and agree to the <a href="/terms">terms and conditions</a></mat-checkbox>
            </section>  
          </ng-container>
        </mat-card-content>
        <mat-card-actions *ngIf="!isProcessed">
          <button mat-raised-button color="primary" [disabled]="!signupForm.valid || !checked" (click)="onSignup($event)">Signup</button>
        </mat-card-actions>
        <mat-card-footer *ngIf="!isProcessed">
          <a class="text-link" routerLink="/login" routerLinkActive="active">Already a member?</a>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
