import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  get isAuthenticated() { return this._securityRepoService.isAuthenticated };
  get userFirstname() { return this._securityRepoService.user.firstName };

  constructor(
    private _router: Router,
    private _securityRepoService: SecurityRepoService
  ) { }

  ngOnInit(): void {
    if(this._securityRepoService.isAuthenticated) {
      this._securityRepoService.refreshAuthStatus();
    }
    else {
      this._router.navigate(['/login']);
    }
  }
}
