<div class="dip-slider" [ngClass]="{'active': isActive}">
  <div class="dip-slider-track-wrapper" #dipSliderTrackWrapperElem>
    <div class="dip-slider-background-border" #dipSliderBackgroundBorderElem></div>
    <div class="dip-slider-background" #dipSliderBackgroundElem></div>
    <div #dipSliderTrackElem class="dip-slider-track">
      <div #leftCdkDrag cdkDrag
          [cdkDragFreeDragPosition]="leftFreeDragPosition"
          class="dip-slider-thumb"
          cdkDragBoundary=".dip-slider-track"
          (cdkDragStarted)="onLeftCdkDragStarted($event)"
          (cdkDragMoved)="onLeftCdkDragMoved($event)"
          (cdkDragEnded)="onLeftCdkDragEnded($event)"
          [cdkDragConstrainPosition]="getLeftConstrainPosition"
          >
      </div>
      <div #rightCdkDrag cdkDrag *ngIf="isDual"
          [cdkDragFreeDragPosition]="rightFreeDragPosition"
          class="dip-slider-thumb"
          cdkDragBoundary=".dip-slider-track"
          (cdkDragStarted)="onRightCdkDragStarted($event)"
          (cdkDragMoved)="onRightCdkDragMoved($event)"
          (cdkDragEnded)="onRightCdkDragEnded($event)"
          [cdkDragConstrainPosition]="getRightConstrainPosition"
          >
      </div>
    </div>
  </div>
  <div class="dip-slider-inputs-wrapper">
    <span #inputPrefixElem class="dip-slider-input-prefix">{{ inputPrefix }}</span>
    <input #lowValueInputElement type="text"
           (keyup)="onKeyupLowValue($event)"
           (keyup.enter)="onEnterLowValue($event)" />
    <span class="dip-slider-inputs-seperator" *ngIf="isDual">−</span>
    <input #hiValueInputElement type="text"
           (keyup)="onKeyupHiValue($event)"
           (keyup.enter)="onEnterHiValue($event)"
           *ngIf="isDual" />
    <span #unitElem class="dip-slider-unit"></span>
  </div>
</div>
