import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SupportRepoService } from 'src/app/repositories/support-repo/support-repo.service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  isLoading: boolean = false;
  isProcessed = false;

  private _errors = [];
  get errors() { return this._errors };

  inquiryForm = this._formBuilder.group({
    name: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    message: ['']
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _supportRepo: SupportRepoService
  ) { }

  ngOnInit(): void {
  }

  getNameError() {
    let fieldControl = this.inquiryForm.get('name');
    if(fieldControl.hasError('required')) {
      return 'Name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getCompanyNameError() {
    let fieldControl = this.inquiryForm.get('companyName');
    if(fieldControl.hasError('required')) {
      return 'Company name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getEmailError() {
    let fieldControl = this.inquiryForm.get('email');
    if(fieldControl.hasError('required')) {
      return 'Email is required';
    }

    if(fieldControl.hasError('email')) {
      return 'Not a valid email';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  onSubmit(event) {
    this._errors = [];
    this.isLoading = true;
    this._supportRepo.createInquiry(this.inquiryForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let result = {
        errors: {
          detail: 'Inquiry request failed!'
        }
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        this.isProcessed = true;

        return;
      }

      let hasErrors = hasResult && result['errors'] && typeof result['errors'] === 'object';
      let errors = hasErrors ? result['errors'] : {};
      this.displayErrors(errors);
    });
  }

  private displayErrors(errors) {
    let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
    let hasInvalidFields = false;
    if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
      for(let violation of errors['validationErrors']['violations']) {
        let title = 'Unknown error';
        if(violation['title'] && typeof violation['title'] === 'string') {
          title = violation['title'];
        }

        let filedControl = this.getControlByName(violation['propertyPath']);
        if(filedControl) {
          filedControl.setErrors({apiError: title});
          filedControl.markAsTouched();
          hasInvalidFields = true;
        }
      }
    }

    let errorMessage = 'An unknown error ocured! Please try again.';
    if(typeof errors['detail'] === 'string') {
      errorMessage = errors['detail'];
    }
    else if(hasInvalidFields) {
      errorMessage = 'Some fields have invalid values!';
    }

    this._errors.push(errorMessage);
  }

  private getControlByName(name) {
    switch(name) {
      case 'name':
          return this.inquiryForm.get('name');
      case 'company_name':
      case 'companyName':
          return this.inquiryForm.get('companyName');
      case 'email':
          return this.inquiryForm.get('email');
      case 'phone':
          return this.inquiryForm.get('phone');
      case 'message':
          return this.inquiryForm.get('message');
    }

    return null;
  }
}
