import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-specify-dialog',
  templateUrl: './specify-dialog.component.html',
  styleUrls: ['./specify-dialog.component.scss']
})
export class SpecifyDialogComponent implements OnInit {
  num: number = 0;

  constructor() { }

  ngOnInit(): void {}

}
