import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersRepoService {

  constructor(private _httpClient: HttpClient) { }

  getAjaxUrl(): string {
    return environment.apiUrl + 'users';
  }

  getUsers(): Observable<any> {
    const requestUrl = environment.apiUrl + 'users';

    return this._httpClient.get<any>(requestUrl);
  }

  getUserDetail(id: number): Observable<any> {
    const href = environment.apiUrl + 'users/' + id;
    const requestUrl = `${href}`;

    return this._httpClient.get<any>(requestUrl);
  }

  getStatistics(userId: number, startDate: Date = null, endDate: Date = null): Observable<any> {
    const href = environment.apiUrl + 'users/statistics/' + userId;
    let requestUrl = `${href}`;
    let paramsSeparator = '?';
    if(startDate) {
      requestUrl += paramsSeparator + `startDate=${encodeURIComponent(startDate.toUTCString())}`;
      paramsSeparator = '&';
    }

    if(endDate) {
      requestUrl += paramsSeparator + `endDate=${encodeURIComponent(endDate.toUTCString())}`;
      paramsSeparator = '&';
    }

    return this._httpClient.get<any>(requestUrl);
  }

  activateAccount(params): Observable<any> {
    const href = environment.apiUrl + 'users/activate';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  disableAccount(params): Observable<any> {
    const href = environment.apiUrl + 'users/disable';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  updateAccount(user): Observable<any> {
    let params = { user: user };
    const href = environment.apiUrl + 'users/update';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  changeMembership(params): Observable<any> {
    const href = environment.apiUrl + 'users/membership-type';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  resetTrialStartDate(params): Observable<any> {
    const href = environment.apiUrl + 'users/trial/reset-start-date';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  endTrial(params): Observable<any> {
    const href = environment.apiUrl + 'users/trial/end';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  resetFreeSearchStartDate(params): Observable<any> {
    const href = environment.apiUrl + 'users/free-search/reset-start-date';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  resetFreeSearchCounters(params): Observable<any> {
    const href = environment.apiUrl + 'users/free-search/reset-counters';
    const requestUrl = `${href}`;
    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  createAccountPassword(params): Observable<any> {
    const href = environment.apiUrl + 'password-confirm';

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  getIsTokenExpired(params): Observable<any> {
    const href = environment.apiUrl + 'check-expiration';

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  regenToken(params): Observable<any> {
    const href = environment.apiUrl + 'regen-token';

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  hasPassword(params): Observable<any> {
    const href = environment.apiUrl + 'has-password';

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  getLoginDuration(params): Observable<any> {
    const href = environment.apiUrl + 'users/login-duration'

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  setMembershipDuration(params): Observable<any> {
    const href = environment.apiUrl + 'users/membership-duration/set'

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href, content, options);
  }

  getMembership(params): Observable<any> {
    const href = environment.apiUrl + 'users/get-membership'

    let content = JSON.stringify(params);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(href,content,options);
  }

  getRoleTitle(role) {
    if(typeof role !== 'string') {
      return '--';
    }

    switch(role) {
      case 'ROLE_USER':
        return 'User';
      case 'ROLE_ADMIN':
        return 'Admin';
      case 'ROLE_MEMBER_BASIC':
        return 'Basic Member';
      case 'ROLE_MEMBER_PRO':
        return 'Pro Member';
    }

    return '-- Unknown --';
  }
}
