import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';

import { OrganizationsRepoService } from 'src/app/repositories/organizations-repo/organizations-repo.service';
import { DipTabulatorComponent } from 'src/app/shared/dip-tabulator/dip-tabulator.component';
import { OrganizationsFormDialogComponent } from './organizations-form-dialog/organizations-form-dialog.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  private _isOrganizationsTableCreated: boolean = false;
  private _organizationsTableWrapperHeight = 0;

  @ViewChild('organizationsSidenavContainer', {read: MatSidenavContainer}) organizationsSidenavContainer: MatSidenavContainer;
  @ViewChild('organizationsTableContainer', {read: ElementRef}) organizationsTableContainer: ElementRef;
  @ViewChild('tabulatorTable', {read: DipTabulatorComponent}) tabulatorTable: DipTabulatorComponent;

  @ViewChild('confirmationDialog', { static: true }) confirmationDialog: TemplateRef<any>;
  @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any>;

  constructor(
    private _matDialog: MatDialog,
    private _organizationsRepo: OrganizationsRepoService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.tabulatorTable.createTable(this.getTableConfig());
    this._isOrganizationsTableCreated = true;
    this._organizationsTableWrapperHeight = this.organizationsTableContainer.nativeElement.offsetHeight;
  }

  ngAfterViewChecked() {
    this.organizationsSidenavContainer.updateContentMargins();

    // this fixes layout of organizations table
    let height = this.organizationsTableContainer.nativeElement.offsetHeight;
    if(this._organizationsTableWrapperHeight !== height) {
      this._organizationsTableWrapperHeight = height;
      if(this._isOrganizationsTableCreated) {
        this.tabulatorTable.table.redraw(true);
      }
    }
  }

  private getTableConfig() {
    let organizationsRepo = this._organizationsRepo;
    let ajaxRequestFunc = (url, config, params) => {
      return new Promise(function(resolve, reject) {
        let organizationsObservable = organizationsRepo.getOrganizations();
        organizationsObservable
        .pipe(catchError(() => {
          reject();
          return observableOf([]);
        }))
        .subscribe(data => {
          resolve(data);
        });
      });
    }

    let tableConfig = {
      height: '100%',
      layout: 'fitDataFill',
      responsiveLayout: true,
      resizableRows: true,
      columns: this.getColumnDefs(),
      placeholder: 'No data to display!',
      ajaxURL: OrganizationsRepoService.urls.organizations,
      ajaxConfig: 'GET',
      ajaxRequestFunc: ajaxRequestFunc,
      ajaxResponse: function(url, params, response) {
        return response.organizations;
      },
      pagination: 'local',
      paginationSize: 20,
      paginationSizeSelector: true,
      paginationDataReceived: { 'data': 'organizations' }
    };

    return tableConfig;
  }

  private getColumnDefs() {
    let thisComponent = this;
    let columnDefs = [
      { title: "ID", field: "id", headerSort: true, headerSortTristate: true },
      {
        title: "Name",
        field: "name",
        headerSort: true,
        headerSortTristate: true,
        editor: 'input',
        editable: false,  // editing will be triggered by double click, not click
        cellDblClick: function(event, cell) {
          event.stopPropagation();
          cell.edit(true);
        },
        cellEdited: function(cell) {
          thisComponent.onOrganizationChanged(cell);
        }
      },
      { title: "Exact matches", 
        field: "exactMatches", 
        headerSort: true, 
        headerSortTristate: true,
        editor: 'input',
        editable: false,
        cellDblClick: function(event, cell) {
          event.stopPropagation();
          cell.edit(true);
        },
        cellEdited: function(cell) {
          thisComponent.onOrganizationChanged(cell, "limit");
        }
      },
      { title: "Maximum exact matches", 
        field: "maximumExactMatches", 
        headerSort: true, 
        headerSortTristate: true,
        editor: 'input',
        editable: false,
        cellDblClick: function(event, cell) {
          event.stopPropagation();
          cell.edit(true);
        },
        cellEdited: function(cell) {
          thisComponent.onOrganizationChanged(cell, "exact matches");
        }
      }
    ];

    return columnDefs;
  }

  onOrganizationChanged(cell, columnName: string = "name") {
    let config = {
      data: {
        msg: 'Are you sure you want to change organization ' + columnName + ' from "' + cell.getOldValue() + '" to "' + cell.getValue() + '"?'
      }
    };

    const dialogRef = this._matDialog.open(this.confirmationDialog, config);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        let updatedOrganization = cell.getData();
        this._organizationsRepo.updateOrganization(updatedOrganization)
        .pipe(catchError(() => {
          return observableOf(false);
        }))
        .subscribe(data => {
          if(data && typeof data === 'object' && data['organization'] && typeof data['organization'] === 'object') {
            cell.clearEdited();
          }
          else {
            let config = {
              data: {
                msg: 'Failed to update organization!'
              }
            };

            this._matDialog.open(this.errorDialog, config);
            cell.restoreOldValue();
          }
        });
      }
      else {
        cell.restoreOldValue();
      }
    });
  }

  onAddNewOrganization(event) {
    const dialogRef = this._matDialog.open(OrganizationsFormDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this.tabulatorTable.table.setData();
      }
    });
  }
}
