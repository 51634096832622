import {Component, EventEmitter, HostListener, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { MatDialog } from '@angular/material/dialog';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { PartRequestsFormDialogComponent } from '../../part-requests/part-requests-form-dialog/part-requests-form-dialog.component';

@Component({
    selector: 'app-row-popup',
    templateUrl: './row-popup.component.html',
    styleUrls: ['./row-popup.component.scss']
})
export class RowPopupComponent implements OnInit {
    @Output() refEmitter = new EventEmitter<string>();
    partNumber: string = '';
    tooltipShowDelay = 400;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
                private _matDialog: MatDialog,
                private _securityRepo: SecurityRepoService) {
    }

    ngOnInit(): void {
        this.partNumber = this.data['part_number'];
    }

    public onRefClicked(refName: string) {
        this.refEmitter.emit(refName);
    }

    public onPartRequestClick() {
      let config = {
        data: {
          defaultValues: {
            partNumber: ''
            }
        }
      };
    
      config.data.defaultValues.partNumber = this.partNumber ? this.partNumber : '';
    
      const dialogRef = this._matDialog.open(PartRequestsFormDialogComponent, config);
    }

    onCopy() {
      let SelectedText = document.getSelection().toString();
      if (SelectedText.length > 0) {
        this._securityRepo.logCopy(SelectedText);
      }
    }
  
    @HostListener('document:keydown.control.a',['$event'])
    onSelectEverything(event:Event) {
      event.preventDefault();
      let range = document.createRange();
      range.selectNode(document.getElementById('main-content'));
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }

    deleteElemFromArray(key: string, position: number) {
      let keyFirstPart = key.substring(0,3);
      let keySecondPart = key.substring(4);

      if (this.data[key].length == 0 || key.length == 0) {
        return false;
      }

      if ((keyFirstPart == 'let' || keyFirstPart == 'tid') && this.data[key][position] != 'NA') {
        let oppositeKey = '';
        if (keySecondPart == 'min') {
          oppositeKey = keyFirstPart + '_max';
        } else {

          oppositeKey = keyFirstPart + '_min';
        }

        if (this.data[oppositeKey][position] != 'NA') {
          this.data[key][position] = 'NA';
          this.data[key + '_ref'][position] = 'NA';
          this.data[key + '_ref_text'][position] = 'NA';
        }
        if (this.data[oppositeKey][position] == 'NA'){
          this.data[key].splice(position, 1);
          this.data[key + '_ref'].splice(position, 1);
          this.data[key + '_ref_text'].splice(position, 1);

          this.data[oppositeKey].splice(position, 1);
          this.data[oppositeKey + '_ref'].splice(position, 1);
          this.data[oppositeKey + '_ref_text'].splice(position, 1);
        }
      }

      if (key == 'dd_fluence' || key == 'survival_voltage') {
        this.data[key].splice(position, 1);
        this.data[key + '_ref'].splice(position, 1);
        this.data[key + '_ref_text'].splice(position, 1);
      }

      return true;
    }

    displayButton(id) {
      let target = document.getElementById(id).getElementsByTagName('button')[0];
      if (target != null) {
        target.style.visibility = 'visible';
      }
    }

    hideButton(id) {
      let target = document.getElementById(id).getElementsByTagName('button')[0];
      if (target != null) {
        target.style.visibility = 'hidden';
      }
    }

    checkArray(array) {
      if (array instanceof Array && array.length > 0) {
        let result = false;
        array.forEach(elem => {
          if(elem != '' && elem != 'NA' && !result) {
            result = true;
          }
        });
        return result;
      }
      return false;
    }
}
