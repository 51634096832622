<div class="product-dialog-header">
  <mat-toolbar class="product-dialog-toolbar">
    <div>
      <button mat-icon-button
              [matTooltip]="isSaved ? 'Entry already saved' : 'Save for later'"
              [matTooltipShowDelay]="tooltipShowDelay"
              [disabled]="isSaved"
              (click)="addToSavedList()"
              class="toolbar-item add-icon"
              >
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button
              matTooltip="Request more information"
              [matTooltipShowDelay]="tooltipShowDelay"
              class="toolbar-item help-icon"
              (click)="onPartRequestClick($event)"
              >
        <mat-icon>help</mat-icon>
      </button>
      <!-- hide this for now
      <button mat-icon-button
              matTooltip="Queue"
              [matTooltipShowDelay]="tooltipShowDelay"
              class="toolbar-item queue-icon"
              >
        <mat-icon>queue</mat-icon>
      </button>
      -->
    </div>
    <div>
      <button mat-icon-button class="toolbar-item">
        <mat-icon [mat-dialog-close]="getResult()" class="colse-icon">close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>

<mat-chip-list *ngIf="isFirstFreeDialog && !isInSampleSet">
  <mat-chip color="warn" selected>You have {{ remainingDialogs }} remaining accesses to part’s detailed summary page.</mat-chip>
</mat-chip-list>
<div mat-dialog-content id="main-content">
  <div class="product-details-grid-wrapper">
    <div class="product-details-grid-row">
      <div class="product-details-col-one">
        <div><strong>Part number: </strong> {{ formattedProduct['part_number'].value }}</div>
        <div><strong>Manufacturer: </strong> {{ formattedProduct['manufacturer'].value }}</div>
        <div><strong>Device function: </strong> {{ formattedProduct['function'].value }}</div>
        <div><strong>Technology: </strong> {{ formattedProduct['technology'].value }}</div>
        <div><strong>Node: </strong> {{ formattedProduct['node'].value }}</div>
        <div><strong>Lot date code / wafer lot no: </strong> {{ formattedProduct['ldc'].value }}</div>
        <div><strong>Test facility: </strong> {{ formattedProduct['facility'].value }}</div>
        <div><strong>Particle: </strong> {{ formattedProduct['particle'].value }}</div>
        <div><strong>Energy: </strong> {{ formattedProduct['energy'].value }}</div>
        <div><strong>Dose rate: </strong> {{ formattedProduct['dose_rate'].value }}</div>
        <div><strong>Bias: </strong> {{ formattedProduct['bias'].value }}</div>
        <div><strong>Test temperature: </strong> {{ formattedProduct['temperature'].value }}</div>
      </div>
      <div class="product-details-col-two">
        <div><strong>TID minimum: </strong> {{ formattedProduct['tid_min'].value }}</div>
        <div><strong>TID maximum: </strong> {{ formattedProduct['tid_max'].value }}</div>
        <div><strong>Displacement damage fluence (cm<sup>2</sup>): </strong> {{ formattedProduct['dd_fluence'].value }}</div>
        <div><strong>SEL LET threshold minimum (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['sel_let_th_min'].value }}</div>
        <div><strong>SEL LET threshold maximum (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['sel_let_th_max'].value }}</div>
        <div><strong>SEGR/SEB/Destructive SEE LET threshold minimum (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['dsee_let_th_min'].value }}</div>
        <div><strong>SEGR/SEB/Destructive SEE LET threshold maximum (MeV-cm<sup>2</sup>/mg): </strong> {{ formattedProduct['dsee_let_th_max'].value }}</div>
        <div><strong>Survival voltage (V): </strong> {{ formattedProduct['survival_voltage'].value }}</div>
      </div>
    </div>
    <div class="product-details-grid-row product-details-field-reference">
      <div><strong>Reference: </strong> {{ formattedProduct['reference'].value }}</div>
    </div>
  </div>
  <hr />
  <div class="mat-body-2">Results:</div>
  <div *ngFor="let result of results">
    {{ result }}
  </div>
  <div cdkFocusInitial></div>
</div>
<footer>
  &copy; Zero-G Radiation Assurance
</footer>
