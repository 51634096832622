<div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="news-wrapper">
    <mat-card class="main-news-card" infiniteScroll [infiniteScrollDistance]="1" (scrolled)="onScrolled()" [scrollWindow]="false">
        <mat-card-header class="main-news-card-header">
            <div class="main-news-card-header-text">
                Updates and News
            </div>
        </mat-card-header>
        <br>
        <mat-card-content class="main-news-content">
            <div class="main-news-count" [hidden]="!isLoaded">
                The database has {{ totalCount }} records as of {{ currentDate | date }}. COTS parts: {{ productsCount }}, and space and military parts: {{ smdProductsCount }}.
                <button class="news-btn" *ngIf="isAdmin && isLoaded" mat-raised-button color="primary" (click)="onAdd()">{{(!getShowAdd)? 'Add' : 'Close'}}</button>
            </div>
            <div [hidden]="!getShowAdd">
                <div class="mat-ae-distance">
                    <mat-form-field floatLabel="auto" appearance="standard">
                        <mat-label>Title</mat-label>
                        <input id="addTitle" matInput type="text" (keyup)="onAddTitleChange()"/>
                    </mat-form-field>
                    <div>
                        <span *ngIf="getAddTitleError" class="error-message">{{ getAddTitleError }}</span>
                    </div>
                    <angular-editor #addText id="addText" [(ngModel)]="setAddText" name="addText" [config]="getMyEditorConfig" (keyup)="onAddTextChange()" (keyup.enter)="onAddSubmit()"></angular-editor>
                    <span *ngIf="getAddTextError" class="error-message">{{ getAddTextError }}</span>
                </div>
                <span [hidden]="getAddTextError" class="error-message">{{getAddTextError}}</span>
                <div class="mat-field-action">
                    <button class="news-btn" mat-raised-button color="primary" (click)="onAddSubmit()">Add</button>
                </div>
            </div>
            
                <div *ngFor="let item of displayingNews; last as isLast" class="news">
                    <div class="news-title">
                        <h1>{{item.title}}</h1>
                    </div>
                    <div class="news-date">
                        <mat-icon>calendar_today</mat-icon>
                        <div class="news-date-text">
                            {{item.createdAt | date:'longDate'}}
                        </div>
                        <div>
                            <button class="news-btn" *ngIf="isAdmin" mat-raised-button color="primary" (click)="onEdit(item.id, item.title, item.text)">{{(!isCurrentlySelectedEdit(item.id))? 'Edit' : 'Close'}}</button>
                            <button class="news-btn" *ngIf="isAdmin" mat-raised-button color="primary" (click)="onDelete(item.id)">Delete</button>
                        </div>
                    </div>
                    <div [hidden]="(!isCurrentlySelectedEdit(item.id))? true : false">
                        <div class="mat-ae-distance">
                            <mat-form-field floatLabel="auto" appearance="standard">
                                <mat-label>Title</mat-label>
                                <input id="{{'editTitle' + item.id}}" matInput type="text" (keyup)="onEditTitleChange()"/>
                            </mat-form-field>
                            <div>
                                <span id="{{'errorTitleEdit' + item.id}}" [hidden]="getEditTitleError.length < 1" class="error-message">{{ getEditTitleError }}</span>
                            </div>
                            <angular-editor id="{{'textEdit' + item.id}}" [(ngModel)]="setEditText" name="editText" [config]="getMyEditorConfig" (keyup)="onEditTextChange()" (keyup.enter)="onEditSubmit(item.id)"></angular-editor>
                        </div>
                        <span id="{{'errorTextEdit' + item.id}}" [hidden]="getEditTextError.length < 1" class="error-message">{{getEditTextError}}</span>
                        <div class="mat-field-action">
                            <button class="news-btn" mat-raised-button color="primary" (click)="onEditSubmit(item.id)">Edit</button>
                        </div>
                    </div>
                    <div [hidden]="(isCurrentlySelectedEdit(item.id))? true : false" class="news-text" [innerHTML]="item.text | safe:'html'"></div>
                    <div *ngIf="!isLast" class="news-divider"></div>
                </div>     
        </mat-card-content>
    </mat-card>
</div>