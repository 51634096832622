<mat-sidenav-container #usersSidenavContainer class="users-sidenav-container">
  <mat-sidenav mode="side" opened class="users-sidenav" [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0"
    [disableClose]="true">
    <!-- Left bar -->
    <div class="products-filters-wrapper">
      <div class="products-filters-toolbar">

        <div class="filters-toolbar-button-container" (click)="applyFilter($event)">
          <button mat-mini-fab class="toolbar-item">
            <mat-icon>search</mat-icon>
          </button>
          <div class="filters-toolbar-icon-text">Search</div>
        </div>
        <div class="filters-toolbar-button-container" (click)="clearFilters($event)">
          <button mat-mini-fab class="toolbar-item">
            <mat-icon>clear</mat-icon>
          </button>
          <div class="filters-toolbar-icon-text">Clear</div>
        </div>
      </div>
      <div class="products-filters-inputs" (scroll)="onScroll($event)">

        <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isIdFilterActive}">
          <mat-icon matPrefix class="left-icon">label</mat-icon>
          <input matInput type="text" placeholder="ID" (keyup.enter)="applyFilter($event)" #idFilterElement />
          <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
        </mat-form-field>

        <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isUsernameFilterActive}">
          <mat-icon matPrefix class="left-icon">label</mat-icon>
          <input matInput type="text" placeholder="Username" (keyup.enter)="applyFilter($event)"
            #usernameFilterElement />
          <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
        </mat-form-field>

        <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isEmailFilterActive}">
          <mat-icon matPrefix class="left-icon">label</mat-icon>
          <input matInput type="text" placeholder="Email" (keyup.enter)="applyFilter($event)" #emailFilterElement />

          <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
        </mat-form-field>

        <div>
          <mat-form-field floatLabel="auto" appearance="standard" [ngClass]="{'active': isCompanyNameFilterActive}">
            <mat-icon matPrefix class="left-icon">label</mat-icon>
            <input matInput type="text" placeholder="Company name" (keyup.enter)="applyFilter($event)"
              #companyNameFilterElement />
            <mat-icon matSuffix (click)="applyFilter($event)" class="inline-search-icon">search</mat-icon>
          </mat-form-field>

          <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #userRole>
            <mat-icon matPrefix class="left-icon">label</mat-icon>
            <div>
              <mat-label>User Role</mat-label>
              <mat-select (selectionChange)="applyFilter($event, 'selectUserRole')" #userRoleFilterElement>
                <mat-option *ngFor="let client of userRoleSelectData" [value]="client.id">
                  {{client.clientName}}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #userStatus>
            <mat-icon matPrefix class="left-icon">label</mat-icon>
            <div>
              <mat-label>Status</mat-label>
              <mat-select (selectionChange)="applyFilter($event, 'selectUserStatus')" #userStatusFilterElement>
                <mat-option *ngFor="let client of statusSelectData" [value]="client.id">
                  {{client.clientName}}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="flex-row" floatLabel="auto" appearance="standard" #organization>
            <mat-icon matPrefix class="left-icon">label</mat-icon>
            <div>
              <mat-label>Organization</mat-label>
              <mat-select (selectionChange)="applyFilter($event, 'selectOrganization')" #organizationFilterElement>
                <mat-option *ngFor="let client of organizationSelectData" [value]="client.id">
                  {{client.clientName}}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>

      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="users-tab-content-container">
      <div #usersTableContainer class="users-table-container">
        <app-dip-tabulator #tabulatorTable></app-dip-tabulator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="context-menu-label-hide-container">
  <button #contextMenuLabelAddElem mat-icon-button matTooltip="Save for later" [matTooltipShowDelay]="tooltipShowDelay">
    <mat-icon>add</mat-icon>
  </button>
  <button #contextMenuLabelAddDisabledElem mat-icon-button disabled matTooltip="Already saved"
    [matTooltipShowDelay]="tooltipShowDelay">
    <mat-icon>add</mat-icon>
  </button>
  <button #contextMenuLabelMoreElem mat-icon-button matTooltip="More details" [matTooltipShowDelay]="tooltipShowDelay">
    <mat-icon>unfold_more</mat-icon>
  </button>
</div>

<ng-template #confirmationDialog let-data>
  <div mat-dialog-title>Action Confirmation</div>
  <div mat-dialog-content>
    <p>{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button [mat-dialog-close]="false" color="warn">No</button>
    <button mat-button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Yes</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog let-data>
  <div mat-dialog-title>Error</div>
  <div mat-dialog-content>
    <p class="error-message">{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button mat-dialog-close color="primary">Ok</button>
  </mat-dialog-actions>
</ng-template>