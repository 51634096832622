<div class="product-dialog-header">
    <mat-toolbar class="product-dialog-toolbar">
      <div>
        <button mat-icon-button
                class="toolbar-item help-icon"
                matTooltip="Request more information"
                [matTooltipShowDelay]="tooltipShowDelay"
                (click)="onPartRequestClick()"
                >
          <mat-icon>help</mat-icon>
        </button>
      </div>
      <div>
        <button mat-icon-button class="toolbar-item">
          <mat-icon [mat-dialog-close]="true" class="colse-icon">close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
</div>


<div mat-dialog-content id="main-content" (copy)="onCopy()">
    <div class="product-details-grid-wrapper">
        <div class="product-details-grid-row">
            <div class="product-details-col-one">
                <div class="one-line">
                    <strong>Part number:</strong> {{ data['part_number'] }}
                </div>
                <div *ngFor="let item of data['let_min']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['let_min'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>LET minimum:</strong>
                        </div>
                        <div id="letMin{{i}}" class="rows" *ngIf="item != 'NA' && item != ''" (mouseover)="displayButton('letMin' + i)" (mouseout)="hideButton('letMin' + i)">
                            <div class="info-div">
                                {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['let_min_ref'][i])">{{data['let_min_ref'][i]}}</span> reference: {{data['let_min_ref_text'][i].substr(0, data['let_min_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('let_min', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['let_min'])">
                    <div class="first-row">
                        <strong>LET minimum:</strong>&nbsp;NA
                    </div>
                </div>
                <div *ngFor="let item of data['let_max']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['let_max'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>LET maximum:</strong> 
                        </div>
                        <div id="letMax{{i}}" class="rows" *ngIf="item != 'NA' && item != ''" (mouseover)="displayButton('letMax' + i)" (mouseout)="hideButton('letMax' + i)">
                            <div class="info-div">
                                {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['let_max_ref'][i])">{{data['let_max_ref'][i]}}</span> reference: {{data['let_max_ref_text'][i].substr(0, data['let_max_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('let_max', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['let_max'])">
                    <div class="first-row">
                        <strong>LET maximum:</strong>&nbsp;NA
                    </div>
                </div>
                <div *ngFor="let item of data['tid_min']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['tid_min'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>TID minimum:</strong> 
                        </div>
                        <div id="tidMin{{i}}" class="rows" *ngIf="item != 'NA' && item != ''" (mouseover)="displayButton('tidMin' + i)" (mouseout)="hideButton('tidMin' + i)">
                            <div class="info-div">
                                {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['tid_min_ref'][i])">{{data['tid_min_ref'][i]}}</span> reference: {{data['tid_min_ref_text'][i].substr(0, data['tid_min_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('tid_min', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['tid_min'])">
                    <div class="first-row">
                        <strong>TID minimum:</strong>&nbsp;NA
                    </div>
                </div>
                <div *ngFor="let item of data['tid_max']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['tid_max'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>TID maximum:</strong> 
                        </div>
                        <div id="tidMax{{i}}" class="rows" *ngIf="item != 'NA' && item != ''" (mouseover)="displayButton('tidMax' + i)" (mouseout)="hideButton('tidMax' + i)">
                            <div class="info-div">
                                {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['tid_max_ref'][i])">{{data['tid_max_ref'][i]}}</span> reference: {{data['tid_max_ref_text'][i].substr(0, data['tid_max_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('tid_max', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['tid_max'])">
                    <div class="first-row">
                        <strong>TID maximum:</strong>&nbsp;NA
                    </div>
                </div>
                <div *ngFor="let item of data['dd_fluence']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['dd_fluence'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>Displacement Damage fluece:</strong> 
                        </div>
                        <div id="ddFluence{{i}}" class="rows" *ngIf="item != ''" (mouseover)="displayButton('ddFluence' + i)" (mouseout)="hideButton('ddFluence' + i)">
                            <div class="info-div">
                                {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['dd_fluence_ref'][i])">{{data['dd_fluence_ref'][i]}}</span> reference: {{data['dd_fluence_ref_text'][i].substr(0, data['dd_fluence_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('dd_fluence', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['dd_fluence'])">
                    <div class="first-row">
                        <strong>Displacement Damage fluece:</strong>&nbsp;NA
                    </div>
                </div>
                <div *ngFor="let item of data['survival_voltage']; index as i; first as isFirst">
                    <div class="div-one-line" *ngIf="checkArray(data['survival_voltage'])">
                        <div *ngIf="isFirst" class="first-row">
                            <strong>Survival voltage:</strong> 
                        </div>
                        <div id="survivalVoltage{{i}}" class="rows" *ngIf="item != ''" (mouseover)="displayButton('survivalVoltage' + i)" (mouseout)="hideButton('survivalVoltage' + i)">
                            <div class="info-div">
                               {{ item }}&nbsp;[<span [mat-dialog-close]="true" class="reference" (click)="onRefClicked(data['survival_voltage_ref'][i])">{{data['survival_voltage_ref'][i]}}</span> reference: {{data['survival_voltage_ref_text'][i].substr(0, data['survival_voltage_ref_text'][i].indexOf(' '))}}]
                            </div>
                            <button mat-icon-button class="small-btn" (click)="deleteElemFromArray('survival_voltage', i)">
                                <mat-icon class="small-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="div-one-line" *ngIf="!checkArray(data['survival_voltage'])">
                    <div class="first-row">
                        <strong>Survival voltage:</strong>&nbsp;NA
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    &copy; Zero-G Radiation Assurance
</footer>


