<div class="top-tool-div">
    <button mat-icon-button class="small-btn" (click)="_snackRef.dismiss()">
        <mat-icon class="small-icon">close</mat-icon>
    </button>
</div>
<div>
    <p>
        You have used the allotted searches for this feature. Request for additional search by submitting an 
        <a href="/inquiry" class="text-link-white">inquiry form</a>
    </p>  
</div>