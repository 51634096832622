import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SecurityRepoService } from './repositories/security-repo/security-repo.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public title = 'draw-it-please';
  pageWrapperClass = '';

  constructor (private router: Router, private _securityRepo: SecurityRepoService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const eventUrl = /(?:\/).+/.exec(event.urlAfterRedirects);
        const currentRoute = (eventUrl || []).join('');

        this.pageWrapperClass = currentRoute;
      }
    });
  }

  ngOnInit() {
    this._securityRepo.logEntry();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this._securityRepo.logExit();
  }
}
