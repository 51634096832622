import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Injectable()
export class CheckAuthStatusInterceptor implements HttpInterceptor {

  constructor(
    private _securityRepoService: SecurityRepoService,
    private _router: Router
  ) {
    //
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let result = next.handle(request)
    .pipe(
      tap(
        event => {},
        error => {
          if(!this.isExceptedUrl(request.url)) {
            this._securityRepoService.refreshAuthStatus();
          }
        }
      )
    );

    return result;
  }

  private isExceptedUrl(url: string): boolean {
    let urlPath = this.extractUrlPath(url);
    if(urlPath === SecurityRepoService.urls.login) {
      return true;
    }

    if(urlPath === SecurityRepoService.urls.isAuthenticated) {
      return true;
    }

    return false;
  }

  private extractUrlPath(url: string): string {
    let urlTree = this._router.parseUrl(url);
    urlTree.queryParams = {};
    return this._router.serializeUrl(urlTree);
  }
}
