import { Component, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  isLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  changePasswordForm = this._formBuilder.group({
    currentPassword: ['', [Validators.required]],
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-Z0-9!@#$%^&]*')
      ]
    ],
    retypePassword: ['']
  });

  constructor(
    private _dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private _formBuilder: FormBuilder,
    private _securityRepo: SecurityRepoService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  getCurrentPasswordError() {
    let fieldControl = this.changePasswordForm.get('currentPassword');
    if(fieldControl.hasError('required')) {
      return 'Current password is required';
    }

    return '';
  }

  getNewPasswordError() {
    let fieldControl = this.changePasswordForm.get('newPassword');
    if(fieldControl.hasError('required')) {
      return 'New password is required';
    }

    if(fieldControl.hasError('minlength')) {
      return 'Password must be at least ' + fieldControl.errors.minlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('maxlength')) {
      return 'Password cannot be longer than ' + fieldControl.errors.maxlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('pattern')) {
      return 'Password contains invalid charcters. Allowed characters are a-z, A-Z, 0-9, and the following special characters: !@#$%^&';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getRetypePasswordError() {
    let fieldControl = this.changePasswordForm.get('retypePassword');
    if(fieldControl.hasError('matched')) {
      return 'Retyped password does not match!';
    }

    return '';
  }

  private displayErrors(errors) {
    let hasInvalidFields = false;
    let hasPasswordValidationError = typeof errors['passwordValidationError'] === 'object' && errors['passwordValidationError'];
    if(hasPasswordValidationError) {
      let title = 'Unknown error';
      if(errors['passwordValidationError']['title'] && typeof errors['passwordValidationError']['title'] === 'string') {
        title = errors['passwordValidationError']['title'];
      }

      let filedControl = this.getControlByName('newPassword');
      if(filedControl) {
        filedControl.setErrors({apiError: title});
        filedControl.markAsTouched();
        hasInvalidFields = true;
      }

      hasInvalidFields = true;
    }

    let errorMessage = 'An unknown error ocured! Please try again.';
    if(typeof errors['detail'] === 'string') {
      errorMessage = errors['detail'];
    }
    else if(hasInvalidFields) {
      errorMessage = 'Some fields have invalid values!';
    }

    this._errors.push(errorMessage);
  }

  private getControlByName(name) {
    switch(name) {
      case 'currentPassword':
      case 'current_password':
          return this.changePasswordForm.get('currentPassword');
      case 'newPassword':
      case 'new_password':
          return this.changePasswordForm.get('newPassword');
      case 'retypePassword':
      case 'retype_password':
          return this.changePasswordForm.get('retypePassword');
    }

    return null;
  }

  retypePasswordValidator(password: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(control.value === password) {
        return null;
      }

      return {matched: {value: false}};
    };
  }

  onNewPasswordChange(event) {
    let newPass = this.changePasswordForm.get('newPassword').value;
    let fieldControl = this.changePasswordForm.get('retypePassword');
    fieldControl.setValidators(this.retypePasswordValidator(newPass));
    fieldControl.updateValueAndValidity();
  }

  onSubmitChangePassword(event) {
    this._errors = [];
    this.isLoading = true;
    this._securityRepo.changePassword(this.changePasswordForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let errMsg = 'Change password request failed!';
      if(err && typeof err === 'object' && err['error'] && typeof err['error'] === 'object') {
        errMsg += ' ' + err['error']['detail'];
      }

      let result = {
        errors: {
          detail: errMsg
        }
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        this._snackBar.open('Your password was successfully changed!', 'ok', { duration: 7000 });
        this._dialogRef.close();

        return;
      }

      let hasErrors = hasResult && result['errors'] && typeof result['errors'] === 'object';
      let errors = hasErrors ? result['errors'] : {};
      this.displayErrors(errors);
    });
  }
}
