import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UploadResponse } from '@kolkov/angular-editor';

@Injectable({
  providedIn: 'root'
})
export class NewsRepoService {

  constructor(private http: HttpClient) {

  }

  public getNews(): Observable<any> {
    const href = environment.apiUrl + 'news';

    return this.http.get<any>(href);
  } 

  public createNews(news: object): Observable<any> {
    const href = environment.apiUrl + 'news';

    return this.http.post<any>(href, news);
  }

  public editNews(news: object): Observable<any> {
    const href = environment.apiUrl + 'news';

    return this.http.put<any>(href, news);
  }

  public deleteNews(id: number): Observable<any> {
    const href = environment.apiUrl + 'news';

    let options = {
      headers: new HttpHeaders(),
      body: {
        id: id
      }
    }
    return this.http.delete<any>(href, options);
  }
}
