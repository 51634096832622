import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router: Router, private _securityRepo: SecurityRepoService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._securityRepo.isAuthenticated) {
      let user = this._securityRepo.user;
      if(user && typeof user === 'object' && Array.isArray(user['roles'])) {
        for(let i = 0; i < user['roles'].length; i++) {
          if(user['roles'][i] === 'ROLE_ADMIN') {
            return true;
          }
        }
      }

      return false;
    }

    this._securityRepo.redirectUrl = state.url;
    return this._router.createUrlTree(['/login']);
  }
}
