<div id="inquiryPageWrapper">
  <div id="colOneWrapper">
    <div id="colOneContent">
      <div class="loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card class="inquiry-card" [class.mat-elevation-z8]="true">
        <mat-card-header>
          <mat-card-title>Inquiry</mat-card-title>
        </mat-card-header>
        
        <p>Contact us with any question or issue. Get a quote. Also ask us about our database contribution plan.</p>

        <mat-card-content [formGroup]="inquiryForm">
          <div *ngIf="errors" class="error-message">
            <ng-container *ngFor="let error of errors">
              {{ error }}<br />
            </ng-container>
          </div>

          <ng-container *ngIf="isProcessed">
            The inquiry was sent successfully. 
          </ng-container>

          <ng-container *ngIf="!isProcessed">
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" required />
              <mat-error *ngIf="inquiryForm.get('name').invalid">{{ getNameError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Company</mat-label>
              <input matInput type="text" formControlName="companyName" required />
              <mat-error *ngIf="inquiryForm.get('companyName').invalid">{{ getCompanyNameError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>E-mail</mat-label>
              <input matInput type="text" formControlName="email" required />
              <mat-error *ngIf="inquiryForm.get('email').invalid">{{ getEmailError() }}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Phone</mat-label>
              <input matInput type="text" formControlName="phone" />
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Message</mat-label>
              <textarea matInput formControlName="message"></textarea>
            </mat-form-field>
        </ng-container>
        </mat-card-content>

        <mat-card-actions *ngIf="!isProcessed">
          <button mat-raised-button color="primary" [disabled]="!inquiryForm.valid" (click)="onSubmit($event)">Submit</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
