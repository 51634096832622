import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OrganizationsRepoService } from 'src/app/repositories/organizations-repo/organizations-repo.service';

@Component({
  selector: 'app-organizations-form-dialog',
  templateUrl: './organizations-form-dialog.component.html',
  styleUrls: ['./organizations-form-dialog.component.scss']
})
export class OrganizationsFormDialogComponent implements OnInit {
  isLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  organizationForm = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _dialogData,
    private _dialogRef: MatDialogRef<OrganizationsFormDialogComponent>,
    private _organizationsRepo: OrganizationsRepoService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    let defaultValues = {
      name: '',
      maximumExactMatches: 500
    };

    if(this._dialogData && this._dialogData.defaultValues) {
      if(typeof this._dialogData.defaultValues.name === 'string') {
        defaultValues.name = this._dialogData.defaultValues.name;
      }

      if(typeof this._dialogData.defaultValues.name === 'number') {
        defaultValues.maximumExactMatches = this._dialogData.defaultValues.maximumExactMatches;
      }
    }

    this.organizationForm = this._formBuilder.group({
      name: [defaultValues.name, [Validators.required]],
      maximumExactMatches: [defaultValues.maximumExactMatches, [Validators.required]]
    });
  }

  private getControlByName(name) {
    switch(name) {
      case 'name':
        return this.organizationForm.get('name');
      case 'maximumExactMatches':
        return this.organizationForm.get('maximumExactMatches');
    }

    return null;
  }

  getNameError() {
    let fieldControl = this.organizationForm.get('name');
    if(fieldControl && fieldControl.hasError('required')) {
      return 'Name is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return 'Unknown error';
  }

  getLimitError() {
    let fieldControl = this.organizationForm.get('maximumExactMatches');
    if(fieldControl && fieldControl.hasError('required')) {
      return 'Limit is required';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return 'Unknown error';
  }

  onSubmitOrganization() {
    this._errors = [];
    this.isLoading = true;
    this._organizationsRepo.createOrganization(this.organizationForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let ajaxResult = {
        errors: {
          detail: 'Failed to add the new organization!'
        }
      };

      return observableOf(ajaxResult);
    }))
    .subscribe(responseData => {
      this.isLoading = false;
      let hasResult = responseData && typeof responseData === 'object';
      if(hasResult && responseData['isSuccess'] === true) {
        this.organizationForm.reset();
        this._dialogRef.close(true);
        this._snackBar.open('The new organization is added!', 'ok', { duration: 7000 });

        return;
      }

      let hasErrors = hasResult && responseData['errors'] && typeof responseData['errors'] === 'object';
      let errors = hasErrors ? responseData['errors'] : {};
      let errorMessage = 'Failed to add the new organization!';
      if(typeof errors['detail'] === 'string') {
        errorMessage = errors['detail'];
      }

      let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
      if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
        for(let violation of errors['validationErrors']['violations']) {
          let title = 'Unknown error';
          if(violation['title'] && typeof violation['title'] === 'string') {
            title = violation['title'];
          }
  
          let filedControl = this.getControlByName(violation['propertyPath']);
          if(filedControl) {
            filedControl.setErrors({apiError: title});
            filedControl.markAsTouched();
          }
        }
      }

      this._errors.push(errorMessage);
    });
  }
}
