<div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="main-wrapper">
    <div class="download-button-wrapper">
        <button *ngIf="isTableAutopop" mat-raised-button color="primary" (click)="onDownloadSpreadsheet()">Download .xlsx</button>
        <mat-icon id="info-icon" class="info-button" (click)="onMoreInfo()">info</mat-icon>
    </div>
    <div *ngIf="!tableReady" class="container">
        <div class="upload-file-message">
            <p>
                Step 1. Upload your spreadsheet<br>
                Step 2. Select column containing the generic manufacturer part numbers<br>
                Step 3. Click the run analysis button<br>
                Note: Acceptable file formats include Excel, CSV, Text files. Your spreadsheet must include the
                generic part numbers as a column. Limited to 50 items for each run for licensed Pro users.
            </p>
        </div>
        <div class="upload-file-drag-and-drop-wrapper">
            <ngx-file-drop id="ngx-drop"
                           dropZoneLabel="Drop files here"
                           (onFileDrop)="dropped($event)"
                           (onFileOver)="onFileOver()"
                           (onFileLeave)="onFileLeave()"
                           [accept]="acceptedFormats"
                           dropZoneClassName="upload-file-drag-and-drop"
                           contentClassName="upload-file-drag-and-drop-content"
                           [directory]="false"
                           [showBrowseBtn]="false"
                           [multiple]="false">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div class="upload-file-drag-and-drop-content">
                        <h1 *ngIf="!fileReady">Drop your files here</h1>
                        <h1 *ngIf="fileReady">{{getFileName()}}</h1>
                    </div>
                    <div class="upload-file-drag-and-drop-buttons">
                        <button type="button"
                                mat-button class="upload-file-drag-and-drop-button"
                                (click)="openFileSelector()">
                            <span class="mat-button-wrapper">
                                Browse
                            </span>
                        </button>
                        <button type="button"
                                mat-button
                                class="upload-file-drag-and-drop-button"
                                (click)="onUploadFile()">
                            <span class="mat-button-wrapper">
                                Upload
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-file-drop>
        </div>
    </div>
    <div [hidden]="displayTable" id="userTable"></div>    
</div>
