import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit {
    selected = this.data[0];

  constructor(@Inject(MAT_DIALOG_DATA) public data: String[]) { }

  ngOnInit(): void {
  }

}
