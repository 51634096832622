<div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<div class="create-password-wrapper">
    <mat-card class="mat-card-instance" *ngIf="!isCreated && !isTokenExpired">
        <mat-card-header class="mat-card-instance-header">
            <mat-card-title>
                Create password
            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-card-instance-content" [formGroup]="signupForm">
            <div *ngIf="error.length > 0" class="error-message">
                {{ error }}
            </div>
            <div class="account-text">
                Create password for your new account
            </div>
            <input type="hidden" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" ngDefaultControl/>
            <mat-form-field class="full-width" floatLabel="auto" appearance="standard">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" formControlName="password" required>
                <mat-error *ngIf="signupForm.get('password').invalid">{{getPasswordError()}}</mat-error>
            </mat-form-field>
        </mat-card-content>
        <mat-card-action class="mat-card-instance-action">
            <button mat-raised-button [disabled]="!signupForm.valid || !isTokenRetrieved" color="primary" (click)="onCreate()">
                Create
            </button>
        </mat-card-action>
    </mat-card>
    <mat-card class="mat-card-instance" *ngIf="isCreated && !isTokenExpired">
        <mat-card-content class="mat-card-instance-content">
            <h1>You have successfuly created password for your account!</h1>
            <p>
                Please <a class="text-link" routerLink="/login">log in</a> to access the site.
            </p>
        </mat-card-content>
    </mat-card>
    <mat-card class="mat-card-instance" *ngIf="isTokenExpired">
        <mat-card-content class="mat-card-instance-content">
            <h1>Your create password Token has expired!</h1>
            <p>
                Please contact the site support by email
            </p>
        </mat-card-content>
    </mat-card>
</div>
