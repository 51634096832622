<div id="loginPageWrapper">
  <div id="colOneWrapper">
    <div id="colOneContent">
      <div class="login-loading-shade" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card class="login-card" [class.mat-elevation-z8]="true">
        <mat-card-header>
          <mat-card-title>Welcome to the Radiation Database!</mat-card-title>
        </mat-card-header>
        <div class="login-rows-count-container">
          <div>
            The database has {{ totalCount }} records as of {{ currentDate | date }}.
          </div>
          <div>
            COTS parts: {{ productsCount }}, and Space and Military parts: {{ smdProductsCount }}.          
          </div>
        </div>
        <div>
          Please Log In
        </div>
        <ng-container *ngFor="let error of errors">
          <div class="error-message">{{ error }}</div>
        </ng-container>
        <mat-card-content>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Username</mat-label>
              <input #usernameElem matInput type="text" (keyup)="onUsernameChange($event)" />
              <mat-hint><span *ngIf="usernameFieldError" class="error-message">{{ usernameFieldError }}</span></mat-hint>
            </mat-form-field>
            <mat-form-field floatLabel="auto" appearance="standard">
              <mat-label>Password</mat-label>
              <input #passwordElem matInput type="password" (keyup)="onPasswordChange($event)" (keyup.enter)="onLogin($event)" />
              <mat-hint><span *ngIf="passwordFieldError" class="error-message">{{ passwordFieldError }}</span></mat-hint>
            </mat-form-field>
            <re-captcha (resolved)="onCaptcha($event)" siteKey="{{siteKey}}"></re-captcha>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button [disabled]="isSubmitDisabled" color="primary" (click)="onLogin($event)">Login</button>
        </mat-card-actions>
        <mat-card-footer>
          <div>
            <a class="text-link" routerLink="/password-reset-request">Forgot your password?</a> | Not registered? <a class="text-link" routerLink="/signup">Create a free account</a>
          </div>
          <div>
            <a class="text-link" routerLink="/products">See sample database</a>
          </div>
          <div>
            <a class="text-link" routerLink="/inquiry">Request more information</a>
          </div>
          <div class="TandC-notice">
            By logging into this website you agree to the <a class="text-link" routerLink="/terms">Terms and Conditions </a>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
