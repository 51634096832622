import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, LOCALE_ID, Inject, SecurityContext, Output, EventEmitter, HostListener } from '@angular/core';
import { CdkDrag, CdkDragEnd, Point, DragRef, CdkDragStart, CdkDragMove } from '@angular/cdk/drag-drop';
import { formatNumber } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dip-slider',
  templateUrl: './dip-slider.component.html',
  styleUrls: ['./dip-slider.component.scss']
})
export class DipSliderComponent implements OnInit, AfterViewInit {
  @Input()
  isDual: boolean;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step: number;

  @Input()
  decimals: number;

  @Input()
  lowValue: number;

  @Input()
  hiValue: number;

  @Input()
  unit: string;

  @Input()
  inputPrefix: string;

  @Output('dipFilterLowValueChange')
  lowValueChange: EventEmitter<number> = new EventEmitter();

  @Output('dipFilterHiValueChange')
  hiValueChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('leftCdkDrag', {read: CdkDrag}) leftCdkDrag: CdkDrag;
  @ViewChild('rightCdkDrag', {read: CdkDrag}) rightCdkDrag: CdkDrag;

  @ViewChild('dipSliderTrackWrapperElem', {read: ElementRef}) dipSliderTrackWrapperElem: ElementRef;
  @ViewChild('dipSliderBackgroundElem', {read: ElementRef}) dipSliderBackgroundElem: ElementRef;
  @ViewChild('dipSliderTrackElem', {read: ElementRef}) dipSliderTrackElem: ElementRef;
  @ViewChild('dipSliderRightTrack', {read: ElementRef}) dipSliderRightTrack: ElementRef;

  @ViewChild('lowValueInputElement', {read: ElementRef}) lowValueInputElement: ElementRef;
  @ViewChild('hiValueInputElement', {read: ElementRef}) hiValueInputElement: ElementRef;

  @ViewChild('unitElem', {read: ElementRef}) unitElem: ElementRef;

  leftFreeDragPosition: Point = {x: 0, y: 0};
  rightFreeDragPosition: Point = {x: 0, y: 0};

  isActive: boolean = false;

  constructor(
    @Inject(LOCALE_ID) private _localeID: string,
    private _domSanitizer: DomSanitizer
  ) { }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  ngOnInit(): void {
    if(this.isDual !== true) {
      this.isDual = false;
    }

    if(isNaN(this.min)) {
      this.min = 0;
    }

    if(isNaN(this.max)) {
      this.max = 100;
    }

    if(this.max <= this.min) {
      this.max = this.min + 10;
    }

    if(isNaN(this.step)) {
      this.step = 0;
    }

    if(isNaN(this.decimals)) {
      this.decimals = 0;
    }

    if(isNaN(this.lowValue) || this.lowValue >= this.max) {
      this.lowValue = this.min;
    }

    if(isNaN(this.hiValue) || this.hiValue <= this.min) {
      this.hiValue = this.max;
    }

    if(this.lowValue >= this.hiValue) {
      this.lowValue = this.min;
    }
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  ngAfterViewInit() {
    this.setLowValue(this.lowValue);
    this.setHiValue(this.hiValue);

    this.unitElem.nativeElement.innerHTML = this._domSanitizer.sanitize(SecurityContext.HTML, this.unit);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onLeftCdkDragStarted(event: CdkDragStart) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let dragRef: any = event.source._dragRef;
    let xOffset = sliderTrackWrapperRect.left + dragRef._pickupPositionInElement.x;

    event.source.data = { xOffset: xOffset, maxX: this.numToX(this.hiValue) };
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onLeftCdkDragMoved(event: CdkDragMove) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();
    let leftX = Math.round(leftThumbRect.left - sliderTrackWrapperRect.left);

    let lowValue = this.xToNum(leftX);
    this.lowValueInputElement.nativeElement.value = formatNumber(lowValue, this._localeID, '1.' + this.decimals);
    this.setInputWidth(this.lowValueInputElement);
    this.setBackground(lowValue, this.hiValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onLeftCdkDragEnded(event: CdkDragEnd) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();
    let leftX = Math.round(leftThumbRect.left - sliderTrackWrapperRect.left);

    let lowValue = this.xToNum(leftX);
    this.setLowValue(lowValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onEnterLowValue(event) {
    this.setLowValue(Number.parseFloat(event.target.value));
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onKeyupLowValue(event) {
    this.setInputWidth(this.lowValueInputElement);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  setLowValue(newValue: number) {
    if(isNaN(newValue) || newValue < this.min) {
      newValue = this.min;
    }
    else if(newValue > this.hiValue) {
      newValue = this.hiValue;
    }

    if(this.step > 0) {
      newValue = Math.round(newValue / this.step) * this.step;
    }

    this.leftFreeDragPosition = { x: 0, y:0 };
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();
    let percent = this.numToPercent(newValue);
    let thumbWidthFraction = leftThumbRect.width * percent / 100;
    this.leftCdkDrag.element.nativeElement.style.left = 'calc(' + percent + '% - ' + thumbWidthFraction + 'px)';

    this.lowValue = newValue;
    this.lowValueInputElement.nativeElement.value = formatNumber(newValue, this._localeID, '1.' + this.decimals);

    this.setBackground(this.lowValue, this.hiValue);
    this.setInputWidth(this.lowValueInputElement);
    this.setActiveState();

    this.lowValueChange.emit(this.lowValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onRightCdkDragStarted(event: CdkDragStart) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let dragRef: any = event.source._dragRef;
    let xOffset = sliderTrackWrapperRect.left + dragRef._pickupPositionInElement.x;

    event.source.data = { xOffset: xOffset, minX: this.numToX(this.lowValue) };
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onRightCdkDragMoved(event: CdkDragMove) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let rightThumbRect = this.rightCdkDrag.element.nativeElement.getBoundingClientRect();
    let rightX = rightThumbRect.left - sliderTrackWrapperRect.left;

    let hiValue = this.xToNum(rightX);
    this.hiValueInputElement.nativeElement.value = formatNumber(hiValue, this._localeID, '1.' + this.decimals);
    this.setInputWidth(this.hiValueInputElement);
    this.setBackground(this.lowValue, hiValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onRightCdkDragEnded(event: CdkDragEnd) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let rightThumbRect = this.rightCdkDrag.element.nativeElement.getBoundingClientRect();
    let rightX = rightThumbRect.left - sliderTrackWrapperRect.left;

    let hiValue = this.xToNum(rightX);
    this.setHiValue(hiValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onEnterHiValue(event) {
    this.setHiValue(Number.parseFloat(event.target.value));
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  onKeyupHiValue(event) {
    this.setInputWidth(this.hiValueInputElement);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  setHiValue(newValue: number) {
    if(!this.isDual) {
      return;
    }

    if(isNaN(newValue) || newValue > this.max) {
      newValue = this.max;
    }
    else if(newValue < this.lowValue) {
      newValue = this.lowValue;
    }

    if(this.step > 0) {
      newValue = Math.round(newValue / this.step) * this.step;
    }

    this.rightFreeDragPosition = { x: 0, y:0 };
    let rightThumbRect = this.rightCdkDrag.element.nativeElement.getBoundingClientRect();
    let percent = this.numToPercent(newValue);
    let thumbWidthFraction = rightThumbRect.width * percent / 100;
    this.rightCdkDrag.element.nativeElement.style.left = 'calc(' + percent + '% - ' + thumbWidthFraction + 'px)';

    this.hiValue = newValue;
    this.hiValueInputElement.nativeElement.value = formatNumber(newValue, this._localeID, '1.' + this.decimals);

    this.setBackground(this.lowValue, this.hiValue);
    this.setInputWidth(this.hiValueInputElement);
    this.setActiveState();

    this.hiValueChange.emit(this.hiValue);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private setBackground(low: number, hi: number) {
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();

    let widthPercent = this.numToPercent(hi - low);
    let thumbWidthFraction = leftThumbRect.width * widthPercent / 100;
    this.dipSliderBackgroundElem.nativeElement.style.width = 'calc(' + widthPercent + '% - ' + thumbWidthFraction + 'px)';

    let leftPercent = this.numToPercent(low);
    thumbWidthFraction = leftThumbRect.width * leftPercent / 100;
    thumbWidthFraction -= leftThumbRect.width / 2;
    this.dipSliderBackgroundElem.nativeElement.style.left = 'calc(' + leftPercent + '% - ' + thumbWidthFraction + 'px)';
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private setInputWidth(inputElement) {
    if(isNaN(inputElement.nativeElement.value.length) || inputElement.nativeElement.value.length < 1) {
      inputElement.nativeElement.style.width = '1em';
    }
    else {
      inputElement.nativeElement.style.width = (0.8 * inputElement.nativeElement.value.length) + 'em';
    }
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private setActiveState() {
    if(this.isDual) {
      if(this.lowValue == this.min && this.hiValue == this.max) {
        this.isActive = false;
      }
      else {
        this.isActive = true;
      }
    }
    else {
      if(this.lowValue == this.min) {
        this.isActive = false;
      }
      else {
        this.isActive = true;
      }
    }
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private xToNum(x: number) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();
    let totalEffectiveWidth = Math.round(sliderTrackWrapperRect.width - leftThumbRect.width);

    let num: number;
    if(x <= 0) {
      num = this.min;
    }
    else if(x >= totalEffectiveWidth) {
      num = this.max;
    }
    else {
      num = x * (this.max - this.min) / totalEffectiveWidth;
    }

    if(this.step > 0) {
      num = Math.round(num / this.step) * this.step;
    }

    return num;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private numToX(num: number) {
    let sliderTrackWrapperRect = this.dipSliderTrackWrapperElem.nativeElement.getBoundingClientRect();
    let leftThumbRect = this.leftCdkDrag.element.nativeElement.getBoundingClientRect();
    let totalEffectiveWidth = sliderTrackWrapperRect.width - leftThumbRect.width;

    let x: number;
    if(num <= this.min) {
      x = 0;
    }
    else if(num >= this.max) {
      x = totalEffectiveWidth;
    }
    else {
      x = num * totalEffectiveWidth / (this.max - this.min);
    }

    return x;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  private numToPercent(num: number) {
    let percent: number;
    if(num <= this.min) {
      percent = 0;
    }
    else if(num >= this.max) {
      percent = 100;
    }
    else {
      percent = num * 100 / (this.max - this.min);
    }

    return percent;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  reset() {
    this.setLowValue(this.min);
    this.setHiValue(this.max);
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  getLeftConstrainPosition(point, dragRef: DragRef) {
    let dragX = point.x - dragRef.data.data.xOffset;
    if(dragX > dragRef.data.data.maxX) {
      return {
        x: dragRef.data.data.maxX + dragRef.data.data.xOffset,
        y: point.y
      };
    }

    return point;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  getRightConstrainPosition(point, dragRef: DragRef) {
    let dragX = point.x - dragRef.data.data.xOffset;
    if(dragX < dragRef.data.data.minX) {
      return {
        x: dragRef.data.data.minX + dragRef.data.data.xOffset,
        y: point.y
      };
    }

    return point;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  getLowValue(): number {
    return this.lowValue;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  getHiValue(): number {
    return this.hiValue;
  }

  /* ************************************************************************************
   * 
   * ************************************************************************************ */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.setLowValue(this.lowValue);
    this.setHiValue(this.hiValue);
  }
}
