<div class="about-wrapper">
    <div class="mat-header">
        <h1 class="mat-header-text">Terms and Conditions</h1>
    </div>
    <div class="mat-body">
        <p>
            The following terminology applies to this Terms and Conditions and Disclaimer Notice and any 
            or all Agreements: “You” and “Your” refers to you, the person accessing this website and 
            accepting the Company’s Terms and Conditions.  “The Company”, “Ourselves”, “We”, “Our” 
            and “Us”, refers to our Company.  “Party”, “Parties”, or “Us”, refers to both the Client and 
            ourselves, or either the Client or ourselves.  Any use of the above terminology or other words in 
            the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and 
            therefore as referring to same. 
        </p>
        
        <p class="mat-accent">
            In using this website You are deemed to have read and agreed to the following 
            Terms and Conditions, which may be updated or changed from time to time by 
            the Company without notice to You.  If You do not agree with any of these 
            Terms and Conditions, do not use this website or our services. Because our 
            services are evolving over time, We may change or discontinue all or any part of 
            our services, at any time and without notice, at our sole discretion. Your 
            continued use of this website following the posting of any changes to this Terms 
            and Conditions constitutes acceptance of those changes.  
        </p>

        <p class="mat-accent">Customer Requirements</p>

        <p>
            The Company’s services are available only to, and may only be used by, individuals who are 
            eighteen (18) years and older who can form legally binding contracts under applicable law.  You 
            represent and warrant that You are at least eighteen (18) years old and that all registration 
            information You submit is accurate and truthful. If You are using the services on behalf of any 
            entity, You represent and warrant that You are authorized to accept these terms and conditions 
            on such entity’s behalf and that such entity agrees to be responsible to Us if You or such entity 
            violates these terms and conditions.
        </p>

        <p class="mat-accent">Registration Information</p>

        <p>
            You are responsible for maintaining Your registration information confidential and secure and 
            for any and all activities that occur under or in connection with Your registration information. 
            You should use a strong password, verify any emails before responding to them with 
            confidential information, and use anti-virus software. You agree to notify the Company of any 
            known or suspected unauthorized use(s) of Your registration information. The Company will not be liable for any injury, loss or damage of any kind arising from or relating to Your failure to 
            safeguard Your registration information. 
        </p>

        <p class="mat-accent">Account Deactivation</p>

        <p>
            The Company may deactivate a user or registered account or at any time for failure to comply 
            with Company guidelines governing use of this website, the Database or this Terms and 
            Conditions.  
        </p>

        <p class="mat-accent">Database License</p>

        <p>
            The Company has developed and is the sole and exclusive owner of an extensive and 
            searchable online database of radiation test data for Electronic and Electromechanical (EEE) 
            components (such as microelectronics, photonic devices and materials), primarily consisting of 
            commercial off-the-shelf parts (COTS), which database is easily searchable by part number and 
            radiation performance specifications (the web interface and the information contained therein 
            collectively referred to herein as the “Database”) 
        </p>

        <p>
            Subject to the terms and conditions herein, and upon approval of Your registration account by 
            Company, Company grants You a free trial period (“Trial License Period”) a non-exclusive, 
			non-transferable, non-sublicensable, royalty-free, trial license to use the Database as permitted 
			in these Terms and Conditions. Under no circumstances may Licensee distribute, sell, 
			package for sale or distribution, incorporate into its marketing or promotional materials, 
			exhibit (whether publicly or privately), allow access to others, or in any way commercially exploit 
			the Database beyond the contemplated uses specified herein.   
        </p>

        <p>
            Each registered account shall only be viewable by an identified group of individuals, units of 
            hardware and/or department as approved in writing by Company in each case.  You agree to 
            maintain and safeguard registered accounts to prevent unauthorized access by third parties.  
        </p>

        <p>
            The rights granted by Company hereunder are limited to Your own direct informational 
            business use and do not permit the transmission of Database contents to third parties, 
            including, without limitation, Your independent contractors or parties or entities to which You 
            are rendering services (“Contracted Third-Parties”), unless such Contracted Third-Parties have 
            their own valid registered accounts.  
        </p>

        <p>
            The information contained within the Database is extracted from published papers and reports. 
            Company does not independently verify such information. It is Your exclusive duty to 
            independently verify Database information to ensure that it is suitable for Your needs.  
        </p>

        <p>
            You understand and acknowledge that the information provided in the Database or on this 
            website is for informational purposes only, and is not meant as complete or accurate 
            representations of the reviewed components or parts. If You have any questions as to the 
            nature or substance of any particular item, please contact Us for clarification. We reserve the 
            right to change or update descriptions without giving You notice, including to correct any 
            errors, inaccuracies, or omissions or for any reason at all. You understand and acknowledge 
            that You have complete oversight over independently verifying the suitability of any 
            components or parts listed in the Database.  You release Us from all liability related to Your use 
            of the Database, as well as from the above activities and those that are wholly under Your 
            control.  
        </p>

        <p class="mat-accent">License Exclusions</p>

        <p>
            Company expressly retains all rights in or to the Database not expressly granted herein, 
            including without limitation the rights to sue for and collect past, present and future damages 
            and to seek and obtain injunctive or any other relief for infringement or misappropriation of the 
            Database. No other rights or licenses under the Database are granted to You or implied other 
            than the rights and licenses expressly granted hereunder.   
        </p>

        <p>
            You will not (i) create or attempt to create, or permit others to create or attempt to create, by 
            disassembling, compiling, scraping, reverse engineering, copying, imitating, referencing, or 
            otherwise incorporating any information, design, function, content, source programs or any 
            part thereof from object programs, or create any form of derivative work or any competing 
            work (whether oral, written, tangible or intangible) that owes in any part its basis in either 
            content or functionality to the Database or knowingly permit or encourage any third party to do 
            so (ii) use the Database in any manner to provide service bureau, time-sharing or other services 
            to third parties, or (c) use the Database in any manner to assist or take part in the development, 
            marketing, or sale of a product potentially competitive with the Database. You agree to not use 
            or permit others to use the Database to solicit new customers or expand offerings to existing 
            customers. 
        </p>

        <p>
            You agree that Company shall be entitled to seek injunctive relief in the event You breach the 
            provisions of these Terms and Conditions in addition to any other available remedies. 
        </p>

        <p class="mat-accent">Indemnification</p>

        <p>
            You shall indemnify and hold harmless Company and its affiliates, principals, employees, 
            officers, directors, consultants, stockholders, representatives and agents, successors and 
            assigns (an “Company Indemnified Party”) from and against all claims, disputes, debts, 
            controversies, obligations, judgments, demands, liens, causes of action, liability, loss, damages, 
            costs and expenses (including reasonable attorneys’ fees and expenses of litigation) 
            (collectively, “Claims”) which a Company Indemnified Party may incur, suffer or be required to 
            pay resulting from or arising in connection with any Claims arising out of or relating to Your use 
            or misuse of the Database or this website or any breach of these Terms and Conditions by You. 
        </p>

        <p class="mat-accent">Remedies</p>

        <p>
            Your sole remedy for a breach of this agreement is an action at law for damages.  You waive any 
            right of rescission or to injunctive or other equitable relief. 
        </p>

        <p class="mat-accent">Limitation of Liability</p>

        <p>
            THE INFORMATION ON THIS WEBSITE IS PROVIDED ON AN “AS IS” BASIS WITHOUT ANY 
            WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED.  EXCEPT AS EXPRESSLY SET FORTH IN 
            THESE TERMS AND CONDITIONS, THE COMPANY DOES NOT MAKE ANY EXPRESS OR IMPLIED 
            WARRANTY AND, IN PARTICULAR, DOES NOT MAKE ANY IMPLIED WARRANTY OF 
            MERCHANTABILITY OR OF FITNESS FOR A PARTICULAR PURPOSE. NEITHER WE NOR ANY THIRD 
            PARTIES PROVIDE ANY WARRANTY OR GUARANTEE AS TO THE ACCURACY, TIMELINESS, 
            PERFORMANCE, COMPLETENESS OR SUITABILITY OF THE INFORMATION AND MATERIALS 
            FOUND OR OFFERED ON THIS WEBSITE FOR ANY PARTICULAR PURPOSE. FURTHER, THE 
            COMPANY MAKES NO WARRANTY THAT YOU OR YOUR PROPERTY WILL NOT BE DAMAGED OR 
            HARMED IN THE USE OF THE WEBSITE OR THE COMPANY’S SERVICES, OR AS TO THE QUALITY, 
            ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, CURRENCY, OR RELIABILITY OF ANY 
            SERVICES OR CONTENT LISTED ON THE WEBSITE.  YOU ACKNOWLEDGE THAT SUCH 
            INFORMATION AND MATERIALS MAY CONTAIN INACCURACIES OR ERRORS AND WE EXPRESSLY 
            EXCLUDE LIABILITY FOR ANY SUCH INACCURACIES OR ERRORS TO THE FULLEST EXTENT 
            PERMITTED BY LAW. 
        </p>

        <p>
            <span class="mat-accent">use of any information or materials on this website is entirely at your own risk</span>, for which 
            we shall not be liable. It shall be your own responsibility to ensure that any products, services 
            or information available through this website meet your specific requirements.
        </p>

        <p>
            IN NO EVENT WILL THE COMPANY, OR ITS AFFILIATES, BE LIABLE WITH RESPECT TO ANY 
            SUBJECT MATTER OF THIS TERMS AND CONDITIONS UNDER ANY CONTRACT, NEGLIGENCE, 
            STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR: (I) ANY SPECIAL, INCIDENTAL 
            OR CONSEQUENTIAL DAMAGES; OR (II) THE COST OF PROCUREMENT FOR SUBSTITUTE 
            PRODUCTS OR SERVICES. THE COMPANY SHALL HAVE NO LIABILITY FOR ANY FAILURE OR DELAY 
            DUE TO MATTERS BEYOND THEIR REASONABLE CONTROL. THE FOREGOING SHALL NOT APPLY 
            TO THE EXTENT PROHIBITED BY APPLICABLE LAW. THE LIMITATIONS OF DAMAGES SET FORTH 
            ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND US. 
        </p>

        <p class="mat-accent">Non-Circumvention</p>

        <p>
            You agree not to in any way, directly or indirectly, solicit, by-pass, compete, avoid, circumvent, 
            or attempt to circumvent the Company relative to the Database or the website.  
        </p>

        <p class="mat-accent">Force Majeure</p>

        <p>
            Neither the Company, nor vendors or services used by the Company in the provision of the 
            Services, if any, shall be liable due to causes beyond the control and without the fault or 
            negligence of such party.  Such causes may include, but are not restricted to, acts of God or of a 
            public enemy, acts of the government in either its sovereign or contractual capacity, fires, 
            floods, epidemics, quarantine restrictions, strikes, freight embargoes, power failure, or failure 
            of the U.S. postal system, but in every case the failure to perform will be beyond the control 
            and without fault or negligence of the party failing to perform. Such party will inform You of any 
            Force Majeure event as soon as practicable after its occurrence.   
        </p>

        <p class="mat-accent">Miscellaneous</p>

        <p>
            The Company’s rights under this Terms and Conditions may be assigned, however You may not 
            assign Your rights under these Terms and Conditions.  This Terms and Conditions shall inure to 
            the benefit of the parties and their successors, administrators, heirs and assigns.  These Terms 
            and Conditions shall be interpreted under the laws of the State of California applicable to 
            contracts entered into and fully to be performed therein.  No waiver of any term hereof shall be 
            deemed to be continuing or be deemed to waive any other term hereof.  Unless the parties 
            have a written agreement signed by each of them that governs the relationship between the 
            parties, these Terms and Conditions constitute the entire understanding of the parties 
            concerning the subject matter hereof; all prior negotiations and understandings are merged 
            herein.  If there is a conflict or contradiction between the provisions of these Terms and 
            Conditions and any other relevant terms and conditions, policies or notices, the provisions of these 
            Terms and Conditions shall prevail in respect of Your use of this website. Should any term 
            or provision of these Terms and Conditions be deemed or held to be invalid or unenforceable, 
            the remaining terms and provisions will continue in full force and effect. Headings are solely for 
            convenience or reference and do not constitute a part of these Terms and Conditions. The 
            terms "including" and "includes" should be read as "including/includes, but not limited to." All 
            rights and remedies given to Us in this Terms and Conditions are cumulative and not exclusive 
            of any other rights or remedies which We otherwise have at law or equity. Unauthorized use of 
            this website may give rise to a claim for damages. 
        </p>

        <p class="mat-accent">Usage Data</p>

        <p>
            We may collect information about You and Your usage of the Website from Your use of the 
            Database and our services via automated data collection tools.  We may use the information 
            We gather from You to facilitate the administration of our services, security, and maintenance, 
            as well as to understand how our services are currently being used and to improve, enhance, or 
            customize the provision of our services. We may also aggregate non-personally identifiable 
            information and share it with third parties or publish it. Automatic data collection may be 
            performed on Our behalf by (or provided to) Our service providers. 
        </p>

        <p>
            We operate the website and mobile app (if any) in compliance with the Children’s Online 
            Privacy Protection Act. This website is not directed to children under the age of 13, nor do We 
            permit registration by nor knowingly collect or use personally identifiable information from 
            children under the age of 13 years. 
        </p>

        <p class="mat-accent">Feedback</p>

        <p>
            We welcome any comments or suggestions You may have. By providing Us such feedback, You 
            grant Us permission to use or incorporate into Our services any suggestions, requests, 
            recommendations, or other feedback that You provide Us, including allowing Us the 
            appropriate intellectual property rights to do so, including granting Us a non-exclusive, 
            worldwide, perpetual, irrevocable, royalty-free, sublicensable, and transferable license to any 
            and all intellectual property rights that You may own or control to use, copy, modify, create 
            derivative works based upon, and otherwise exploit Your feedback for any purpose. You hereby 
            waive all moral rights in any such information or content. We are not responsible for the 
            accuracy of any information, feedback, or content made on the website by third parties.  
        </p>

        <p class="mat-accent">Links To and From this Website</p>

        <p>
            You may not create a link to any page of this website without our prior written consent.  You 
            may not frame or enclose any Company trademark, logo or other proprietary information, 
            including the images found on the website, mobile app (if any), or associated with our services 
            in any text, layout, or design of any website without Our express written consent. We do not 
            monitor or review the content of other party’s websites which are linked to from this Website. 
            A link from Our website to any third-party site, location, or source does not signify Our 
            endorsement of the site or its contents. The Company is not responsible for the privacy 
            practices or content of such third-party websites. The Company is not responsible for any loss 
            or damage in whatever manner, howsoever caused, resulting from Your disclosure of 
            information to third parties with which You have come into contact via this website.
        </p>

        <p class="mat-accent">Intellectual Property</p>

        <p>
            You acknowledge that the website, the content on the website, and the Database are protected 
            by patent, copyright, trademark, trade secret and other laws of the United States. Except as 
            expressly authorized herein, You may not reproduce, transmit, sell, display, distribute, publish, 
            broadcast, circulate, modify, disseminate or commercially exploit the website, Database, and 
            any and all content produced or posted by Us in any manner (including electronic, print or 
            other media now known or hereafter developed) without our written consent. You agree not to 
            remove, alter, or obscure any patent, copyright, trademark, service mark, or other proprietary-
            rights notices incorporated in or accompanying any of Our products, services or in any of Our 
            content.
        </p>

        <p class="mat-accent">Prohibitions</p>

        <p>
            You may not access, tamper with, or otherwise interfere with the non-public areas of this 
            website, nor Our computers, nor Our computer systems. Nor may You attempt to bypass, 
            deactivate, or impair in any way any safety or security measure implemented by Us or any of 
            our third-party affiliates.  You may not attempt to collect from this website or through using 
            Our services any personally identifiable information from any other user. You may not violate 
            any applicable law or regulation or assist or encourage anyone else to do any of the foregoing. 
            You agree not to use the website or any other of Our content for any unlawful purposes and to 
            comply with any and all requests from Us to protect our respective rights. We expressly 
            prohibit the use of devices (including software) designed to provide repeated automated access 
            to the website, including for any commercial purpose. You will not reverse engineer, 
            disassemble, decompile, decrypt, concert the software to human perceivable form, create 
            derivative works of, or otherwise exploit for any commercial purpose the website or any of Our 
            technology, including any software associated with the website. You may not use a third party’s 
            account or registration to access the website. You may not deep-link to the website or use a 
            robot, spider, web crawler, or extraction software, automated process, device, or similar 
            methods to scrape, copy, datamine, or monitor or similarly glean or extract any portion of the 
            website, Database or any Company-owned content. We reserve the right to take any and all 
            measures necessary to prevent any of these, including suspension, denial or termination of 
            Your access to the website, Database, or our services.
        </p>

        <p class="mat-accent">International Use</p>

        <p>
            Neither We nor our affiliates make any representation that this website or content appearing 
            on this website is appropriate or available for use in locations outside the United States. Those 
            who choose to access this website from other locations are responsible for compliance with any 
            applicable local laws and other applicable laws or regulations regarding the transmission of 
            technical data exported from the United States to the country in which you reside. 
        </p>

        <p class="mat-accent">Legal Disputes</p>

        <p>
            You agree that any dispute, claim, or controversy concerning these Terms and Conditions, or 
            any dispute, claim or controversy arising out of or relating to any interpretation, construction, 
            performance or breach of these Terms and Conditions, shall be settled by arbitration to be held 
            in Santa Clara County, California in accordance with the rules then in effect of the American 
            Arbitration Association. There shall be a single arbitrator who may grant injunctions or other 
            relief in such dispute or controversy. The decision of the arbitrator shall be final, conclusive and 
            binding on the parties to the arbitration. Judgment may be entered on the arbitrator’s decision 
            in any court having jurisdiction. You and the Company will pay the costs and expenses of such 
            arbitration in such proportions as the arbitrator shall decide, and You and the Company shall 
            separately pay its own counsel fees and expenses. You agree to the applicable governing law 
            above without regard to choice or conflicts of law rules, and to the exclusive jurisdiction of the 
            applicable courts above. You hereby waive any right to jury trial in connection with any action 
            or litigation in any way arising out of or related to these Terms and Conditions. 
        </p>

        <p>
            Except as otherwise specified in this Agreement, all notices, permissions and approvals 
            hereunder shall be in writing and shall be deemed to have been given upon: (i) personal 
            delivery, (ii) the second business day after mailing, (iii) the second business day after sending by 
            confirmed facsimile, or (iv) the first business day after sending by e-mail (provided e-mail shall 
            not be sufficient for notices of termination or an indemnifiable claim). 
        </p>

        <p class="mat-accent">Termination</p>

        <p>
            We may terminate Your access to and use of the Database or any of Our content or services, at 
            Our sole discretion, at any time and without notice to You, in which case any and all rights 
            granted to You herein will immediately automatically terminate. 
        </p>

        <p class="mat-accent">Electronic Signature</p>

        <p>
            You agree that Your use of Our services constitutes Your electronic signature, acceptance, and 
            agreement under the United States federal E-SIGN legislation and will meet the requirements 
            of an original signature. You may not raise the use of an electronic signature as a defense to the 
            enforcement of this Agreement. 
        </p>

        <p class="mat-accent">Copyright Notice</p>

        <p>
            The information on this website is the copyrighted work of the Company and is protected under 
            United States and worldwide copyright laws and treaty provisions.  This website contains 
            material which is owned by or licensed to Us. This material includes, but is not limited to, the 
            design, layout, look, appearance and graphics. Reproduction is prohibited other than in 
            accordance with the copyright notice, which forms part of this Terms and Conditions.
        </p>

        <p>© {{year.getFullYear()}} Zero-G Radiation Assurance LLC</p>
    </div>
</div>
