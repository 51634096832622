import { Component, OnInit } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ProductsRepoService } from 'src/app/repositories/products-repo/products-repo.service';

@Component({
  selector: 'app-news-and-updates',
  templateUrl: './news-and-updates.component.html',
  styleUrls: ['./news-and-updates.component.scss']
})
export class NewsAndUpdatesComponent implements OnInit {
  currentDate = new Date();
  productsCount: any = '---';
  smdProductsCount: any = '---';
  totalCount: any = '---';

  constructor(private _productsRepo: ProductsRepoService) { }

  ngOnInit(): void {
    this.loadProductsCount();
  }

  private loadProductsCount() {
    this._productsRepo.getCount()
    .pipe(catchError((err) => {
      this.productsCount = '---';

      return observableOf(false);
    }))
    .subscribe(result => {
      let hasResult = result && typeof result === 'object';
      if(hasResult && typeof result['productsCount'] === 'number') {
        this.totalCount = result['total'];
        this.productsCount = result['productsCount'];
        this.smdProductsCount = result['smdProductsCount'];
      }
    });
  }
}
