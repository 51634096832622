<mat-sidenav-container #organizationsSidenavContainer class="organizations-sidenav-container">
  <mat-sidenav mode="side" opened class="organizations-sidenav"
               [fixedInViewport]="false" [fixedTopGap]="0" [fixedBottomGap]="0" [disableClose]="true">
    <!-- Left bar -->
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="organizations-tab-content-container">
      <div class="organizations-actions-container">
        <button mat-raised-button color="primary" (click)="onAddNewOrganization($event)">New Organization</button>
      </div>
      <div #organizationsTableContainer class="organizations-table-container">
        <app-dip-tabulator #tabulatorTable></app-dip-tabulator>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #confirmationDialog let-data>
  <div mat-dialog-title>Action Confirmation</div>
  <div mat-dialog-content>
    <p>{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button [mat-dialog-close]="false" color="warn">No</button>
    <button mat-button mat-flat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Yes</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog let-data>
  <div mat-dialog-title>Error</div>
  <div mat-dialog-content>
    <p class="error-message">{{ data.msg }}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button mat-dialog-close color="primary">Ok</button>
  </mat-dialog-actions>
</ng-template>
