<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="user-profile-page-wrapper">
  <mat-card class="user-profile-card" [class.mat-elevation-z8]="true" [ngClass]="{ 'not-edit-mode': !isEditMode }">
    <mat-card-header>
      <mat-card-title>User Profile</mat-card-title>
      <mat-card-subtitle *ngIf="userProfile && userProfile.membershipType">
        {{ userProfile.membershipType }} Member
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="userProfile && userProfile.roles">
        {{ userProfile.roles.join(", ") }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content [formGroup]="userProfileForm">
      <div *ngIf="errors" class="error-message">
        <ng-container *ngFor="let error of errors">
          {{ error }}<br />
        </ng-container>
      </div>

      <mat-form-field floatLabel="auto" appearance="standard" hideRequiredMarker>
        <mat-label>Username</mat-label>
        <input matInput type="text" formControlName="username" required readonly />
      </mat-form-field>

      <mat-form-field #firstNameFormField floatLabel="auto" appearance="standard"
                      hideRequiredMarker [ngClass]="{ 'edit-mode': isEditMode }">
        <mat-label>First Name</mat-label>
        <input #firstNameInput matInput type="text" formControlName="firstName" required [readonly]="!isEditMode" />
        <mat-icon matSuffix color="accent" *ngIf="isEditMode">edit</mat-icon>
        <mat-error *ngIf="userProfileForm.get('firstName').invalid">{{ getFirstNameError() }}</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto" appearance="standard"
                      hideRequiredMarker [ngClass]="{ 'edit-mode': isEditMode }">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="lastName" required [readonly]="!isEditMode" />
        <mat-icon matSuffix color="accent" *ngIf="isEditMode">edit</mat-icon>
        <mat-error *ngIf="userProfileForm.get('lastName').invalid">{{ getLastNameError() }}</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto" appearance="standard"
                      hideRequiredMarker [ngClass]="{ 'edit-mode': isEditMode }">
        <mat-label>Company</mat-label>
        <input matInput type="text" formControlName="companyName" [readonly]="!isEditMode" />
        <mat-icon matSuffix color="accent" *ngIf="isEditMode">edit</mat-icon>
        <mat-error *ngIf="userProfileForm.get('companyName').invalid">{{ getCompanyNameError() }}</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="auto" appearance="standard" hideRequiredMarker>
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="email" required readonly />
      </mat-form-field>

      <button mat-raised-button (click)="onChangePassword($event)" *ngIf="!isEditMode">
        Change Password
      </button>
    </mat-card-content>

    <mat-card-actions>
      <button mat-raised-button color="warn" (click)="onCancelEditUserProfile($event)" *ngIf="isEditMode">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="onEditUserProfile($event)" [disabled]="!userProfile" *ngIf="!isEditMode">
        Edit
      </button>
      <button mat-raised-button color="primary" [disabled]="!userProfileForm.valid"
              (click)="onUpdateUserProfile($event)" *ngIf="isEditMode"
              >
        Update
      </button>
    </mat-card-actions>
  </mat-card>
</div>
