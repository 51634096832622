<div class="loading-shade" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div class="change-password-dialog-content" [formGroup]="changePasswordForm">
  <h3 mat-dialog-title>Change Password</h3>
  <div mat-dialog-content cdkFocusInitial>
    <div *ngIf="errors" class="error-message">
      <ng-container *ngFor="let error of errors">
        {{ error }}<br />
      </ng-container>
    </div>

    <p>
      <mat-form-field floatLabel="auto" appearance="standard">
        <mat-label>Current Password</mat-label>
        <input matInput type="password" formControlName="currentPassword" required cdkFocusInitial />
        <mat-error *ngIf="changePasswordForm.get('currentPassword').invalid">{{ getCurrentPasswordError() }}</mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field floatLabel="auto" appearance="standard">
        <mat-label>New Password</mat-label>
        <input matInput type="password" formControlName="newPassword" required (change)="onNewPasswordChange($event)" />
        <mat-error *ngIf="changePasswordForm.get('newPassword').invalid">{{ getNewPasswordError() }}</mat-error>
      </mat-form-field>
    </p>

    <p>
      <mat-form-field floatLabel="auto" appearance="standard">
        <mat-label>Retype Password</mat-label>
        <input matInput type="password" formControlName="retypePassword" required />
        <mat-error *ngIf="changePasswordForm.get('retypePassword').invalid">{{ getRetypePasswordError() }}</mat-error>
      </mat-form-field>
    </p>
  </div>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="changePasswordForm.invalid"
            (click)="onSubmitChangePassword($event)"
            >
      Submit
    </button>
  </mat-dialog-actions>
</div>
