import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  isLoading: boolean = false;

  private _isProcessed: boolean = false;
  get isProcessed() { return this._isProcessed };

  private _errors = [];
  get errors() { return this._errors };

  passwordResetForm = this._formBuilder.group({
    username: ['', [Validators.required]],
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern('[a-zA-Z0-9!@#$%^&]*')
      ]
    ],
    retypePassword: [''],
    resetToken: ['']
  });

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _securityRepo: SecurityRepoService
  ) { }

  ngOnInit(): void {
    let newPasswordCtrl = this.passwordResetForm.get('newPassword');
    let retypePasswordCtrl = this.passwordResetForm.get('retypePassword');
    retypePasswordCtrl.setValidators(this.retypePasswordValidator(newPasswordCtrl));

    this._activatedRoute.paramMap.subscribe(paramMap => {
      let resetToken = paramMap.get('resetToken');
      let resetTokenCtrl = this.passwordResetForm.get('resetToken');
      resetTokenCtrl.setValue(resetToken);
    });
  }

  getUsernameError() {
    let fieldControl = this.passwordResetForm.get('username');
    if(fieldControl.hasError('required')) {
      return 'Username is required';
    }

    return '';
  }

  getNewPasswordError() {
    let fieldControl = this.passwordResetForm.get('newPassword');
    if(fieldControl.hasError('required')) {
      return 'New password is required';
    }

    if(fieldControl.hasError('minlength')) {
      return 'Password must be at least ' + fieldControl.errors.minlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('maxlength')) {
      return 'Password cannot be longer than ' + fieldControl.errors.maxlength.requiredLength + ' charcters';
    }

    if(fieldControl.hasError('pattern')) {
      return 'Password contains invalid charcters. Allowed characters are a-z, A-Z, 0-9, and the following special characters: !@#$%^&';
    }

    if(fieldControl.hasError('apiError')) {
      return fieldControl.errors.apiError;
    }

    return '';
  }

  getRetypePasswordError() {
    let fieldControl = this.passwordResetForm.get('retypePassword');
    if(fieldControl.hasError('matched')) {
      return 'Retyped password does not match!';
    }

    return '';
  }

  retypePasswordValidator(passwordCtrl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(control.value === passwordCtrl.value) {
        return null;
      }

      return {matched: {value: false}};
    };
  }

  onNewPasswordKeyup(event) {
    let retypePassword = this.passwordResetForm.get('retypePassword');
    retypePassword.updateValueAndValidity();
  }

  onSubmitResetPassword(event) {
    if(this.passwordResetForm.invalid) {
      return;
    }

    this._errors = [];
    this.isLoading = true;
    this._securityRepo.resetPassword(this.passwordResetForm.value)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let errMsg = 'Change password request failed!';
      if(err && typeof err === 'object' && err['error'] && typeof err['error'] === 'object') {
        errMsg += ' ' + err['error']['detail'];
      }

      let result = {
        errors: {
          detail: errMsg
        }
      };

      return observableOf(result);
    }))
    .subscribe(result => {
      this.isLoading = false;
      let hasResult = result && typeof result === 'object';
      if(hasResult && result['isSuccess'] === true) {
        this._isProcessed = true;

        return;
      }

      let hasErrors = hasResult && result['errors'] && typeof result['errors'] === 'object';
      let errors = hasErrors ? result['errors'] : {};
      this.displayErrors(errors);
    });
  }

  private displayErrors(errors) {
    let hasInvalidFields = false;
    let hasPasswordValidationError = typeof errors['passwordValidationError'] === 'object' && errors['passwordValidationError'];
    if(hasPasswordValidationError) {
      let title = 'Unknown error';
      if(errors['passwordValidationError']['title'] && typeof errors['passwordValidationError']['title'] === 'string') {
        title = errors['passwordValidationError']['title'];
      }

      let filedControl = this.passwordResetForm.get('newPassword');
      filedControl.setErrors({apiError: title});
      filedControl.markAsTouched();
      hasInvalidFields = true;
    }

    let errorMessage = 'An unknown error occurred! Please try again.';
    if(typeof errors['detail'] === 'string') {
      errorMessage = errors['detail'];
    }
    else if(hasInvalidFields) {
      errorMessage = 'Some fields have invalid values!';
    }

    this._errors.push(errorMessage);
  }
}
