import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';
import { ProductsRepoService } from 'src/app/repositories/products-repo/products-repo.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  errors: string[] = [];
  siteKey: string = environment.recaptcha.siteKey;
  token: string|null = null;
  isSubmitDisabled: boolean = true;

  usernameFieldError: string = '';
  passwordFieldError: string = '';

  currentDate = new Date();
  productsCount: any = '---';
  smdProductsCount: any = '---';
  totalCount: any = '---';

  @ViewChild('usernameElem', {read: ElementRef}) usernameElem: ElementRef;
  @ViewChild('passwordElem', {read: ElementRef}) passwordElem: ElementRef;

  constructor(
    private _securityRepo: SecurityRepoService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _productsRepo: ProductsRepoService
  ) { }

  ngOnInit(): void {
    this.loadProductsCount();
    if(this._securityRepo.isAuthenticated) {
      this._router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.usernameElem.nativeElement.focus();
      this._changeDetectorRef.detectChanges();
    }, 300);
  }

  onCaptcha(event) {
    if (event) {
      this.token = event;
      this.isSubmitDisabled = false;
    } 
  }

  onLogin(event) {
    this.resetErrors();
    if(!this.validateFields()) {
      return;
    } else if (!this.token) {
      this.errors.push('Failed to login!');
      this.errors.push('Captcha check failed.');
      return;
    }

    let credentials = {
      username: this.usernameElem.nativeElement.value,
      password: this.passwordElem.nativeElement.value,
      recaptcha: this.token
    };

    this.usernameElem.nativeElement.value = '';
    this.passwordElem.nativeElement.value = '';

    this.isLoading = true;
    this._securityRepo!.login(credentials)
    .pipe(catchError(() => {
      this.isLoading = false;
      return observableOf(false);
    }))
    .subscribe(result => {
      this.isLoading = false;
      if(result === true) {
        this.errors = [];
        this._router.navigateByUrl(this._securityRepo.redirectUrl)
        .then(navigateResult => {
          if(navigateResult === false) {  // navigation can fail because of user permissions
            this._router.navigate(['/']);
          }
        });
      }
      else {
        this.errors.push('Failed to login!');
        this.errors.push('Make sure username and password you entered are valid.');
      }
    });
  }

  private validateFields() {
    let isValid: boolean = true;
    if(this.usernameElem.nativeElement.value.length < 1) {
      this.usernameFieldError = 'Invalid username!';
      isValid = false;
    }

    if(this.passwordElem.nativeElement.value.length < 1) {
      this.passwordFieldError = 'Invalid password!';
      isValid = false;
    }

    return isValid;
  }

  private resetErrors() {
    this.errors = [];
    this.usernameFieldError = '';
    this.passwordFieldError = '';
  }

  private loadProductsCount() {
    this._productsRepo.getCount()
      .pipe(catchError((err) => {
        this.productsCount = '---';

        return observableOf(false);
      }))
      .subscribe(result => {
        let hasResult = result && typeof result === 'object';
        if(hasResult && typeof result['productsCount'] === 'number') {
          this.totalCount = result['total'];
          this.productsCount = result['productsCount'];
          this.smdProductsCount = result['smdProductsCount'];
        }
      });
  }

  onUsernameChange(event) {
    this.usernameFieldError = '';
  }

  onPasswordChange(event) {
    this.passwordFieldError = '';
  }
}
