<div class="password-reset-page-wrapper">
  <mat-card class="password-reset-card" [class.mat-elevation-z8]="true">
    <mat-card-content>
      <h3>Password Reset</h3>
      <div [formGroup]="passwordResetForm" cdkFocusInitial *ngIf="!isProcessed">
        <div *ngIf="errors" class="error-message">
          <ng-container *ngFor="let error of errors">
            {{ error }}<br />
          </ng-container>
        </div>

        <p>
          <mat-form-field floatLabel="auto" appearance="standard">
            <mat-label>Username</mat-label>
            <input matInput type="text" formControlName="username" required tabindex="0" cdkFocusInitial />
            <mat-error *ngIf="passwordResetForm.get('username').invalid">{{ getUsernameError() }}</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field floatLabel="auto" appearance="standard">
            <mat-label>New Password</mat-label>
            <input matInput type="password" formControlName="newPassword" required (keyup)="onNewPasswordKeyup($event)" />
            <mat-error *ngIf="passwordResetForm.get('newPassword').invalid">{{ getNewPasswordError() }}</mat-error>
          </mat-form-field>
        </p>

        <p>
          <mat-form-field floatLabel="auto" appearance="standard">
            <mat-label>Retype Password</mat-label>
            <input matInput type="password" formControlName="retypePassword" required />
            <mat-error *ngIf="passwordResetForm.get('retypePassword').invalid">{{ getRetypePasswordError() }}</mat-error>
          </mat-form-field>
        </p>
        <input type="hidden" formControlName="resetToken" />

        <mat-card-actions align="end">
          <button mat-raised-button color="primary" [disabled]="passwordResetForm.invalid"
                  (click)="onSubmitResetPassword($event)"
                  >
            Submit
          </button>
        </mat-card-actions>
      </div>

      <div *ngIf="isProcessed">
        Your password was successfully changed!<br />
        <a routerLink="/login">Login</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>
